import { firebase } from '../firebase-config';
import { BaseUrl, COLLECTIONS } from '../config';
import { getLoginToken } from '../storage';
import { getuniqueId } from '../helpers';
import axiosInstance from '../axios';
// import imaps from 'imap-simple';
// import { simpleParser } from 'mailparser';
// import _ from 'lodash';


const collection = COLLECTIONS.ISSUES;

/**
 * Get List of issue
 * @param {*} onSuccess
 * @param {*} onError
 */

function datetimest() {
  var d = new Date();
  var c = d.toLocaleString('en-US', { timeZone: 'America/New_York' });

  var sensibleFormat = new Date(c);
  var milliseconds = sensibleFormat.getTime();

  return milliseconds;
}

export async function getIssuesList(onSuccess, onError) {
  firebase
    .firestore()
    .collection(collection)
    // .orderBy("status")
    .orderBy('updatedAt', 'desc')
    // .where("status", 'not-in', ["Closed","Resolved"])
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
}

/**
 * Get Issue detail by issue id
 * @param {*} id  : issueId
 * @param {*} onSuccess
 * @param {*} onError
 */


export async function getOneIssue(id, onSuccess, onError) {
  try {


    // const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };
                  
    // await axiosInstance.get(`${BaseUrl}fetchIssueResponseEmailNew`, {
    //   headers: {
    //     'Content-Type': 'application/json',
    //     withCredentials: true,
    //     ...authHeaders,
    //   },
    // });


    firebase
      .firestore()
      .collection(collection)
      .doc(id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          onError && onError({ status: false, error: 'Issue not found' });
        }
        let data = doc.data();
        onSuccess && onSuccess({ status: true, data: { ...data, id: doc.id } });
      })
      .catch((error) => {
        onError && onError({ status: false, error: error });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}

/**
 * Add new issue to databaes with attahcments, (issues will add only from employee so not used in admin dashboard)
 * @param {*} formData
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function addIssue(formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  let allattachments = [];
  if (
    formData.attachments &&
    formData.attachments !== '' &&
    formData.attachments.length > 0
  ) {
    const promises = [];
    formData.attachments.forEach(async (file) => {
      let filename = new Date().getTime() + file.name;
      promises.push(uploadFile(file, filename));
    });
    allattachments = await Promise.all(promises);
  }
  formData.attachments = allattachments;
  firebase
    .firestore()
    .collection(collection)
    .add({
      ...formData,
      createdBy: currentUserId,
      createdAt: datetimest(),
    })
    .then(
      async (doc) => {
        const responseGroupItem = formData;
        responseGroupItem.id = doc.id;
        onSuccess && onSuccess({ status: true, data: responseGroupItem });
      },
      (error) => {
        console.log(`ERROR: ${collection} ${error}`);
        onError({ status: false, error: error });
      }
    )
    .catch((e) => {
      console.log(`ERROR: ${collection} ${e}`);
      onError({ status: false, error: e });
    });
}

/**
 * Update data of perticular Issue
 * @param {*} id : issueId
 * @param {*} formData : only pass Data to be updated
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function updateIssue(id, formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  let document = await firebase.firestore().collection(collection).doc(id);
  let allattachments = [];
  if (
    formData.attachments &&
    formData.attachments !== '' &&
    formData.attachments.length > 0
  ) {
    const promises = [];
    formData.attachments.forEach(async (file) => {
      let filename = new Date().getTime() + file.name;
      promises.push(uploadFile(file, filename));
    });
    allattachments = await Promise.all(promises);
  }
  formData.attachments = allattachments;
  const docContent = {
    ...formData,
    updatedAt: datetimest(),
    updatedBy: currentUserId,
  };
  document
    .set(docContent, { merge: true })
    .then(async (doc) => {
      onSuccess &&
        onSuccess({
          status: true,
          message: 'Updated successfully',
          response: doc,
        });
    })
    .catch((error) => {
      if (error.code === 5) {
        onError && onError({ status: false, message: 'Not Found' });
      }
      onError && onError({ status: false, error: error.code });
    });
}

/**
 * Permanantly Remove Issue from database
 * @param {*} id
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function deleteIssue(id, onSuccess, onError) {
  const document = firebase.firestore().collection(collection).doc(id);
  document
    .delete()
    .then(() => {
      onSuccess && onSuccess({ status: true, message: 'Delete successfull' });
    })
    .catch((error) => {
      onError && onError({ status: false, error: error });
    });
}

/**
 * Upload file to firestore
 * @param {*} file  : file blob, object
 * @param {*} filename : name of file
 * @returns : data of file after uploaded, including download url, type, size
 */
export const uploadFile = (file, filename) => {
  return new Promise((resolve, reject) => {
    let currentUserId = firebase.auth().currentUser.uid;
    const ref = firebase.storage().ref(currentUserId).child(filename);

    const task = ref.put(file);

    task.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot) =>
        console.log((snapshot.bytesTransferred / snapshot.totalBytes) * 100),
      (error) => {
        console.log('error', error);
        return reject(error);
      },
      async (result) => {
        const url = await task.snapshot.ref.getDownloadURL();
        return resolve({
          url: url,
          contentType: task.snapshot.metadata.contentType,
          name: task.snapshot.metadata.name,
          size: task.snapshot.metadata.size,
        });
      }
    );
  });
};

/**
 * Add Response to Issue  (called by admin or user)
 * @param {*} id : IssueID
 * @param {*} formData : Response Data to be saved in responses
 * @param {id:'',name:''} respondBy : Name and id of user who adding response
 * @param {*} onSuccess : success callback function
 * @param {*} onError : error callback function
 */
export async function addIssueResponse(
  id,
  formData,
  respondBy,
  issueId,
  alternativeData,
  onSuccess,
  onError
) {
  let currentUserId = firebase.auth().currentUser.uid;
  let document = await firebase.firestore().collection(collection).doc(id);
  let docSnapshot = await document.get();
  let existingData = docSnapshot.exists ? docSnapshot.data() : {};
  let newid = getuniqueId('res');
  let allattachments = [];
  if (
    formData.attachments &&
    formData.attachments !== '' &&
    formData.attachments.length > 0
  ) {
    const promises = [];
    formData.attachments.forEach(async (file) => {
      let filename = new Date().getTime() + file.name;
      promises.push(uploadFile(file, filename));
    });
    allattachments = await Promise.all(promises);
  }
  formData.attachments = allattachments;

  let sendformdata = {};

  if (formData.escalated) {
    sendformdata.status = formData.escalatedTo;
  
    if (formData.escalatedTo === "Escalate") {
      sendformdata.escalated_to_admin = true;
    }
  }
  const paddedIssueId = String(issueId).padStart(4, '0');

  const emailString = String(formData.to || "").trim();
  let newEmails = emailString !== "" ? emailString.split(",").map(email => email.trim()) : [];
  
  let existingEmails = existingData.email || [];
  sendformdata.email = Array.from(new Set([...existingEmails, ...newEmails]));

  sendformdata.responses = {
    ...existingData.responses,
    [newid]: {
      ...formData,
      respondBy: respondBy,
      respondOn: datetimest(),
    },
  };
  const docContent = {
    ...sendformdata,
    updatedAt: datetimest(),
    status: 'Pending',
    updatedBy: currentUserId,
  };
  document
  .set(docContent, { merge: true })
  .then(async (doc) => {
    if (emailString !== "") {



      const authHeaders = { Authorization: `Bearer ${getLoginToken()}` };

                  
      await axiosInstance.post(
        `${BaseUrl}sendEmailData`,
        {
          email: formData.to.join(', '),
          subject: `(${alternativeData.clientNames}) ProTeams Issue - #${paddedIssueId}`,
          contenttxt: alternativeData.previousMessage ? 
          
          `<p>Newest Message - Issue ${paddedIssueId} (${alternativeData.newTimestamp})</p>

          <p>${alternativeData.currentMessage}</p> 
                     
          <pre>
          
          
          
          

          </pre>

          <p>#############</p>

          <p>Previous Message - Issue ${paddedIssueId} (${alternativeData.previousTimestamp})</p>
        
          <p>${alternativeData.previousMessage}</p>

          <p>&nbsp;</p>

          <p>To see a full list of messages on this issue, please use this <a href='${`http://issues.proteams.io/${id}`}' target='_blank' style="color: blue;">link</a>.</p>

          <p style="color:red";>Please note, replies to this email will be forwarded to all parties currently associated with this ticket.</p>`
            
          : `<p>Newest Message - Issue ${paddedIssueId} (${alternativeData.newTimestamp})</p>

          <p>${alternativeData.currentMessage}</p>

          <p>&nbsp;</p>

          <p>To see a full list of messages on this issue, please use this <a href='${`http://issues.proteams.io/${id}`}' target='_blank' style="color: blue;">link</a>.</p>
          
          <p style="color:red";>Please note, replies to this email will be forwarded to all parties currently associated with this ticket.</p>`,
          issues: true,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            withCredentials: true,
            ...authHeaders,
          },
        }
      );
    }
      onSuccess &&
        onSuccess({
          status: true,
          message: 'Updated successfully',
          response: doc,
        });
    })
    .catch((error) => {
      if (error.code === 5) {
        onError && onError({ status: false, message: 'Not Found' });
      }
      onError && onError({ status: false, message: 'An error occurred while updating the document', error: error });
    });
}

/**
 * Close Issue by setting status to Closed
 * @param {*} id : issueId
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function closeIssueApi(id, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  let document = await firebase.firestore().collection(collection).doc(id);

  const docContent = {
    status: 'Closed',
    archive: true,
    closedOn: datetimest(),
    updatedAt: datetimest(),
    updatedBy: currentUserId,
  };
  document
    .set(docContent, { merge: true })
    .then(async (doc) => {
      onSuccess &&
        onSuccess({
          status: true,
          message: 'Updated successfully',
          response: doc,
        });
    })
    .catch((error) => {
      if (error.code === 5) {
        onError && onError({ status: false, message: 'Not Found' });
      }
      onError && onError({ status: false, error: error.code });
    });
}


export async function unArchiveIssue(id, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  let document = await firebase.firestore().collection(collection).doc(id);

  const docContent = {
    status: 'Pending',
    archive: false,
    closedOn: firebase.firestore.FieldValue.delete(),
    updatedAt: datetimest(),
    updatedBy: currentUserId,
  };
  document
    .set(docContent, { merge: true })
    .then(async (doc) => {
      onSuccess &&
        onSuccess({
          status: true,
          message: 'Updated successfully',
          response: doc,
        });
    })
    .catch((error) => {
      if (error.code === 5) {
        onError && onError({ status: false, message: 'Not Found' });
      }
      onError && onError({ status: false, error: error.code });
    });
}


export async function getIssueListCount(formData, onSuccess, onError) {
  let colRef = firebase.firestore().collection(collection);

  try {
    const querySnapshot = await colRef.where('status', 'in', [...formData.status]).get();

    let statusCount = {};
    querySnapshot.forEach((doc) => {
      let data = doc.data();
      statusCount[data?.status] = statusCount[data?.status] ? statusCount[data?.status] + 1 : 1;
    });

    formData.status.forEach(status => {
      if (!statusCount[status]) {
        statusCount[status] = 0;
      }
    });

    onSuccess && onSuccess([statusCount]);
  } catch (error) {
    console.log('Error getting documents: ', error);
    onError && onError(error);
  }
}


export async function updateBoldStatus(id, storedData, onSuccess, onError) {
  if (!id || id.trim() === "") {
    onError && onError({ status: false, message: "Id is Missing" });
    return;
  }

  try {
    const colRef = firebase.firestore().collection(collection);
    const updateData = {
      storedData: storedData,
      updatedAt: datetimest(),
    };

    await colRef.doc(id).set(updateData, { merge: true });
    onSuccess && onSuccess({ status: true, data: storedData });

  } catch (error) {
    console.error("Error updating document:", error);
    onError && onError({ status: false, message: error.message });
  }
}