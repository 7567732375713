import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, Divider, Popover, Typography } from "@material-ui/core";

export function SuppplyDataDialog({ open, onClose, listData }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-20" style={{
        fontSize: '22px', fontWeight: "800", color: "#3d3d3d", paddingRight: "10px", paddingLeft: "10px",
        paddingTop: "5px", paddingBottom: "5px", marginLeft: "12px"
      }}>
        Supplies
        <Divider className="mt-10" />
      </Typography>
      <DialogContent style={{ paddingTop: "5px", paddingBottom: "5px" }}>
        {Array.isArray(listData) ? (
          <div>
            {listData.map((item, index) => (
              <div key={index} className="mv-10">
                <Typography variant="body" style={{ color: "#606060", fontSize: "16px" }}>{item?.title} ({item?.qty})</Typography>
              </div>
            ))}
          </div>
        ) : (
          <div>{listData}</div>
        )}
      </DialogContent>
      <DialogActions className="d-flex flex-justify-center">
        <Button variant="contained" onClick={onClose} className="ph-30 mb-15" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function SuccessDialog({ open, onClose, Archive }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
      {`${Archive === true ? 'The order has been UnArchived and is in process. Please review in Manage Supplies.' : 'The order has been approved and is in process. Please review in Manage Supplies.'}`}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function SuccessGroupDeleteDialog({ open, onClose, group }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <div className="d-flex flex-center flex-direction-column">
      <DialogContent className="mb-10 mt-30" style={{fontSize:"18px", lineHeight:"22px"}}>
          You have successfully deleted the {group?.title} Group
      </DialogContent>
      <DialogActions className="mb-20">
        <Button onClick={onClose} variant="contained" className="ph-35" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Close
        </Button>
      </DialogActions>
      </div>
    </Dialog>
  );
};

export function RejectDailog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent>
        The order has been rejected and has been added to Archived Orders.
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ConfirmationsDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <Typography variant="body" className="mt-25" style={{ fontSize: '20px', fontWeight: "800", color: "#3d3d3d", padding: "10px", marginLeft: "12px" }}>
        Confirm Approval
        <Divider className="mt-15" /></Typography>
      <DialogContent className="mb-10">
        Are you sure you want to approve this request?
      </DialogContent>
      <DialogActions className="mt-20 mb-15" >
        <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export function ConfirmationsUnArchiveDialog({ open, onClose, onConfirm }){
  return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
          <Typography variant="body" className="mt-25" style={{ fontSize: '20px', fontWeight: "800", color: "#3d3d3d", padding: "10px", marginLeft: "12px" }}>
              Unarchive
              <Divider className="mt-15" /></Typography>
          <DialogContent className="mb-10">
              Are you sure you want to "UnArchive" this item?
          </DialogContent>
          <DialogActions className="mt-20 mb-15" >
              <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
                  No
              </Button>
              <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
                  Yes
              </Button>
          </DialogActions>
      </Dialog>
  );
};

export function ConfirmationDeleteChannelDialog({ open, onClose, onConfirm, chItem }){
  return (
      <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
          <Typography variant="body" className="mt-25" style={{ fontSize: '20px', fontWeight: "800", color: "#3d3d3d", padding: "10px", marginLeft: "12px" }}>
              Remove Channel
              <Divider className="mt-15" /></Typography>
          <DialogContent className="mb-10">
           Are you Sure you want to permanent remove "{chItem ? chItem['groupName'] : ""}" channel?
          </DialogContent>
          <DialogActions className="mt-20 mb-15" >
              <Button variant="contained" onClick={onClose} className="ph-35 mr-5" style={{ backgroundColor: "gray", color: "white", borderRadius: "10px" }}>
                  No
              </Button>
              <Button variant="contained" onClick={onConfirm} className="ph-35 mr-10" style={{ backgroundColor: "green", color: "white", borderRadius: "10px" }}>
                  Yes
              </Button>
          </DialogActions>
      </Dialog>
  );
};

export function CustomTooltip ({ listData, title }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <Typography
        aria-owns={open ? "mouse-over-popover" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className="color-Darkblue"
        style={{
          cursor: "pointer",
        }}
      >{title}
      </Typography>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="tooltip-heading2 p-5">
          <div className="tooltip-container2">
            {Array.isArray(listData) ? (
              <div>
                {listData.map((item, index) => (
                  <div key={index}>
                    <div>{item.title} ({item.qty})</div>
                  </div>
                ))}
              </div>
            ) : (
              <div>{listData}</div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};