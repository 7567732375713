/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from "react";
import { useNavigate } from "react-router";
import CommonContext from "../../hooks/commonContext";
import { Container, Button, TextField, IconButton } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import { Columns, ColumnsRes } from "./employeeTableColumns";
import TableToolbar from "../../components/Table/TableToolbar";
import EditIcon from "@material-ui/icons/Edit";
import LockIcon from "@material-ui/icons/Lock";
import { useEffect } from "react";
import {
  getEmployeesList,
  updateEmployee,
} from "../../services/apiservices/employee";
import { useMediaQuery } from "@mui/material";
import ManagePagesCommonCard from "../../components/ResponsiveCardComponent/ManageListPages/ManageListPagesResponsive";

const Employee = (props) => {
  const commonContext = useContext(CommonContext);
  const history = useNavigate();
  const [allEmployees, setAllEmployees] = React.useState([]);
  const [searchTerm, setsearchTerm] = React.useState("");
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [expanded, setExpanded] = React.useState(null);

  const fetchEmployeesList = async () => {
    commonContext?.setLoader(true);
    getEmployeesList(
      {},
      (res) => {
        if (res) {
          let allEmp = res?.map((eitem) => {
            return {
              ...eitem,
              userName: `${eitem?.firstName} ${eitem?.lastName}`,
            };
          });
          setAllEmployees(allEmp);
          commonContext?.setLoader(false);
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        console.log("reserror", resError);
        commonContext?.setLoader(false);
      }
    );
  };
  useEffect(() => {
    fetchEmployeesList();
  }, []);

  const redirectToCreateEmp = (employeeId) => {
    if (typeof employeeId !== "undefined" && employeeId !== "")
      history("/createEmp/" + employeeId);
    else history("/createEmp");
  };

  const loginWithEmployee = (empDetails) =>{
    // console.log(empDetails);
      history("/ViewEmployeeApp/" + empDetails?.id);
    
  }

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "email" :
        return (
          <>
            <a  href={`https://mail.google.com/mail/?view=cm&fs=1&to=${rcellItem[hcellItem?.id]}`} target="_blank" className="resLinks" style={{ overflowWrap: "anywhere" }}>
              {rcellItem[hcellItem?.id]}
            </a>
          </>
        );
      case "action":
        return (
          <>
          {rcellItem?.role === 'Employee'? 
          <IconButton
              aria-label="Login"
              color="primary"
              size="small"
              onClick={() => loginWithEmployee(rcellItem)}
            >
              <LockIcon />
            </IconButton>:null}
            <IconButton
              aria-label="Edit"
              color="primary"
              size="small"
              onClick={() => redirectToCreateEmp(rcellItem?.id)}
            >
              <EditIcon />
            </IconButton>
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const tableRightComponent = () => {
    return (
      (isMobile || isLandscape) ? (
        <div className="d-flex flex-direction-column pl-5">
          <div className="d-flex flex-justify-center mb-15">
          <Button
            className="w-norm clientsummry pv-5 fw-bold"
            style={{fontSize:"15px", borderRadius:"2px"}}
            size={"small"}
            variant="contained"
            color="primary"
            onClick={() => redirectToCreateEmp()}
          >
            Add New Employee
          </Button>
          </div>
          <div className="w350">
            <TextField
              fullWidth={true}
              size="small"
              name="searchTerm"
              id="searchTerm"
              label="Search"
              variant="outlined"
              placeholder="Search"
              className="input customSearch mb-15"
              onChange={(event) => {
                setsearchTerm(event.target.value);
              }}
              value={searchTerm ? searchTerm : ""}
            />
          </div>
        </div>
      ) : (
      <div className="d-flex groupsearch">
        <div className="mh-3">
          <TextField
            fullWidth={true}
            size="small"
            name="searchTerm"
            id="searchTerm"
            label=""
            variant="outlined"
            placeholder="Search Employee..."
            className="input"
            onChange={(event) => {
              setsearchTerm(event.target.value);
            }}
            value={searchTerm ? searchTerm : ""}
          />
        </div>
        <Button
          className="flatbutton w-norm"
          size={"small"}
          variant="contained"
          color="primary"
          onClick={() => redirectToCreateEmp()}
        >
          Add New Employee
        </Button>
      </div>
      )
    );
  };

  const OnSwitchChange = (rowitem, newstatus) => {
    commonContext?.setLoader(true);
    updateEmployee(
      rowitem?.id,
      { status: newstatus },
      (res) => {
        if (res?.data?.status === true) {
          commonContext?.setLoader(false);
          fetchEmployeesList();
        } else {
          commonContext?.setLoader(false);
        }
      },
      (resError) => {
        commonContext?.setLoader(false);
        fetchEmployeesList();
      }
    );
  };
  const checkRowSearch = (searchTerm, row) => {
    return (
      String(row.acuityId)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.email)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.userName)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase()) ||
      String(row.phoneNumber)
        .toLowerCase()
        .includes(String(searchTerm).toLowerCase())||
        String(row.employeerole)
          .toLowerCase()
          .includes(String(searchTerm).toLowerCase())
    );
  };
  const filteredList =
    searchTerm !== ""
      ? allEmployees?.filter((item) => checkRowSearch(searchTerm, item))
      : allEmployees;

  const handleExpandClick = (id) => {
    setExpanded(prevExpanded => (prevExpanded === id ? null : id));
  };
    
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Employee"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Employee", to: "/" },
          ]}
          BreadCrumbCurrentPageTitle={"Manage Employee"}
          showLastSeen={true}
        />

        <div className={`sectionBox2`}>
          <TableToolbar
            title={isMobile || isLandscape ? "" : "Employee"}
            rightComponent={tableRightComponent()}
          />
          {(!isMobile && !isLandscape) && 
          <ThemeTable
            rows={filteredList}
            headCells={Columns}
            hidePagination={true}
            renderRowCell={renderRowCell}
            // selectedRows={selectedRows}
            OnSwitchChange={OnSwitchChange}
          />
        } 
        {(isMobile || isLandscape) && (
          <>
            {filteredList.map((record, index) => {
              const isOpen = expanded === record?.id;
              return (
                <ManagePagesCommonCard  
                index={index}
                record={record}
                isOpen={isOpen}
                handleExpandClick={handleExpandClick}
                OnSwitchChange={OnSwitchChange}
                redirectToDetails={redirectToCreateEmp}
                loginWithEmployee={loginWithEmployee}
                columns={ColumnsRes}
                Employee={true}
                />
              );
            })}
            </>
           )}
        </div>
      </Container>
    </>
  );
};
export default Employee;
