const Columns = [
    // { id: "checkbox", numeric: true, label: "", isSortable: false },
    {
        id: "title",
        numeric: false,
        disablePadding: true,
        label: "Title",
        isSortable: true,
    },
    {
        id: "description",
        numeric: false,
        disablePadding: true,
        label: "Description",
        isSortable: true,
    },
    // {
    //   id: "status",
    //   numeric: false,
    //   disablePadding: false,
    //   label: "Status",
    //   isSortable: true,
    // },

    { id: "action", numeric: false, disablePadding: true, label: "Action", rowclassName:"text-right", className:"text-right pr-30" },
];

const ColumnsRes = [
    {
        id: "description",
        numeric: false,
        disablePadding: true,
        label: "Description",
        isSortable: true,
    },
    { id: "action", numeric: false, disablePadding: true, label: "Action", rowclassName:"text-right", className:"text-right pr-30" },
];
const Rows = [
    { acuityID: 'PC009485GS', employeename: "William Thomas",contact: '+44 1632 960042', email: "exampleemail7647@gmail.com"},
    { acuityID: 'PC009485GS', employeename: "James lastname",contact: '+44 1632 960042', email: "exampleemail7647@gmail.com"},
    { acuityID: 'PC009485GS', employeename: "Anthony Thomas",contact: '+44 1632 960042', email: "exampleemail7647@gmail.com"},
];

export { Columns, ColumnsRes, Rows };
  