import React from "react";
import { Container, TextField } from "@material-ui/core";
import PageTitle from "../../components/pageTitle/PageTitle";
import ThemeTable from "../../components/Table/ThemeTable";
import { Columns, ColumnsRes } from "./groupsTableColumns";
import DialogComponent from "../../components/DialogComponent/DialogComponent";
import TableToolbar from "../../components/Table/TableToolbar";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import DeleteGroup from "./Groups/DeleteGroup";
import EditGroup from "./Groups/EditGroup";
import EditIcon from "@material-ui/icons/Edit";
import { useEffect } from "react";
import { getGroupsList } from "../../services/apiservices/groups";
import AddNewGroup from "./Groups/AddNewGroup";
import { useMediaQuery } from "@mui/material";
import ManageGroupsCommonCard from "../../components/ResponsiveCardComponent/ManageListPages/ManageGroupPagesResponsive";

const ManageGroups = (props) => {
  const [allGroups, setAllGroups] = React.useState([]);
  const [searchGroupTerm, setSearchGroupTerm] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

  const fetchGroupsList = async () => {
    setIsLoading(true);
    getGroupsList(
      {},
      (res) => {
        if (res) {
          const sortedGroups = res.sort((a, b) => b.createdAt - a.createdAt);
          setAllGroups(sortedGroups);
          setIsLoading(false);
        }
      },
      (resError) => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    fetchGroupsList();
  }, []);

  const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
    switch (hcellItem.id) {
      case "action":
        return (
          <>
            <DialogComponent
              buttonWrapClassName={"d-inline"}
              buttonClassName={"iconButton color-primary"}
              buttonProps={{ size: "small" }}
              buttonLabel={<EditIcon />}
              ModalTitle={"Update Group"}
              ModalContent={
                <EditGroup group={rcellItem} onSuccess={fetchGroupsList} />
              }
            />

            <DialogComponent
              buttonWrapClassName={"d-inline"}
              buttonClassName={"iconButton color-danger"}
              buttonProps={{ size: "small" }}
              buttonLabel={<DeleteOutlineIcon />}
              ModalTitle={"Delete Group"}
              ModalContent={
                <DeleteGroup group={rcellItem} onSuccess={fetchGroupsList} />
              }
            />
          </>
        );

      default:
        return rcellItem[hcellItem?.id];
    }
  };

  const tableRightComponent = () => {
    return (
      (isMobile || isLandscape) ? (
        <div className="d-flex flex-direction-column">
        <div className="d-flex flex-justify-center mb-15">
          <DialogComponent
          buttonWrapClassName={"d-inline"}
          buttonClassName={"w-norm w-170 pv-8 fw-bold buttonRess"}
          buttonProps={{
            color: "primary",
            variant: "contained",
          }}
          buttonLabel={"Add New Group"}
          ModalTitle={"Add New Group"}
          ModalContent={<AddNewGroup onAddSuccess={fetchGroupsList} />}
        />
          </div>
        <div className="w350">
          <TextField
            fullWidth={true}
            size="small"
            name="searchGroupTerm"
            id="searchGroupTerm"
            label="Search"
            variant="outlined"
            placeholder="Search Group"
            className="input customSearch mb-15"
            onChange={(event) => {
              setSearchGroupTerm(event.target.value);
            }}
            value={searchGroupTerm ? searchGroupTerm : ""}
          />
        </div>
      </div>
      ) : (
      <div className="d-flex groupsearch">
        <div className="mh-12">
          <TextField
            fullWidth={true}
            size="small"
            name="searchGroupTerm"
            id="searchGroupTerm"
            label=""
            variant="outlined"
            placeholder="Search Group..."
            className="input"
            onChange={(event) => {
              setSearchGroupTerm(event.target.value);
            }}
            value={searchGroupTerm ? searchGroupTerm : ""}
          />
        </div>
        <DialogComponent
          buttonWrapClassName={"d-inline"}
          buttonClassName={"flatbutton w-norm"}
          buttonProps={{
            size: "small",
            color: "primary",
            variant: "contained",
          }}
          buttonLabel={"Add New Group"}
          ModalTitle={"Add New Group"}
          ModalContent={<AddNewGroup onAddSuccess={fetchGroupsList} />}
        />
      </div>
     )
    );
  };

  const filteredGroups =
    searchGroupTerm !== ""
      ? allGroups?.filter((group) =>{
        return  String(group.title)
        .toLowerCase()
        .includes(String(searchGroupTerm).toLowerCase()) || String(group.description)
        .toLowerCase()
        .includes(String(searchGroupTerm).toLowerCase())
      })         
      : allGroups;
  return (
    <>
      <Container>
        <PageTitle
          pageTitle="Groups"
          showBreadcrumb={[
            { label: "Home", to: "/" },
            { label: "Employee", to: "/manageEmp" },
          ]}
          showLastSeen={true}
        />
        <div className={`sectionBox`}>
          <TableToolbar title={isMobile || isLandscape ? "" : "Groups"} rightComponent={tableRightComponent()} />
          {(!isMobile && !isLandscape) && 
          <ThemeTable
            rows={filteredGroups}
            headCells={Columns}
            hidePagination={true}
            renderRowCell={renderRowCell}
            // selectedRows={selectedRows}
            isLoading={isLoading}
          />
        }
        {(isMobile || isLandscape) && (
          <>
            {filteredGroups.map((record, index) => {
              return (
                <ManageGroupsCommonCard 
                index={index}
                record={record}
                columns={ColumnsRes}
                fetchGroupsList={fetchGroupsList}
                Group={true}
                />
              );
            })}
            </>
           )}
        </div>
      </Container>
    </>
  );
};
export default ManageGroups;
