import React, { useState } from 'react';
import KeyboardArrowRightSharpIcon from '@mui/icons-material/KeyboardArrowRightSharp';
import {
    Card,
    CardHeader,
    CardContent,
    Divider,
    List,
    ListItem,
    ListItemText,
    Button,
    Typography,
    CardActions
} from '@material-ui/core';
import { useMediaQuery } from '@mui/material';
import styles from "../Reports.module.scss";
import ThemeTable from '../../../Table/ThemeTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faEnvelope, faFileCsv, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { CSVLink, CSVDownload } from "react-csv";
import moment from "moment-timezone";

const getDateValue = (employee, date) => {
    if (employee[date] !== null && employee[date] !== undefined) {
        return `${employee[date]} Hrs.`;
    } else {
        return " ";
    }
};


const ResponsiveTable = ({
    rows,
    getColumns,
    isLoading,
    renderRowCell,
    extraHeader,
    detailedView,
    setDetailedView,
    fileName,
    onClickPdf,
    onClickExcel,
    onClickCsv,
    clientsListCSV,
    onClickEmail,
    payPeriod,
    getPayPeriodDropDownOptions
}) => {
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

    const columns = getColumns(isMobile, isLandscape);

    const groupedByDate = rows.reduce((acc, row) => {
        columns.forEach(column => {
            if (moment(column.id, "YYYY-MM-DD", true).isValid()) {
                if (!acc[column.id]) {
                    acc[column.id] = [];
                }
                acc[column.id].push({
                    ...row,
                    date: column.id,
                });
            }
        });
        return acc;
    }, {});

    const sortedDateKeys = Object.keys(groupedByDate).sort((a, b) => new Date(a) - new Date(b));

    const handleViewDetails = (date, employeeRecords) => {
        setDetailedView({ date, employeeRecords });
    };

    const selectedPayPeriodOption = getPayPeriodDropDownOptions.find(option => option.value === payPeriod);

    const filteredGroupedByDate = payPeriod && payPeriod !== "None"
        ? Object.keys(groupedByDate).reduce((acc, date) => {
            const dateMoment = moment(date, "YYYY-MM-DD");
            const [start, end] = selectedPayPeriodOption.label.split(" - ").map(d => moment(d, "ddd MMM DD, YYYY"));
            if (dateMoment.isBetween(start, end, null, '[]')) {
                acc[date] = groupedByDate[date];
            }
            return acc;
        }, {})
        : groupedByDate;

    const aggregatedRecords = payPeriod && payPeriod !== "None"
        ? Object.values(filteredGroupedByDate).flat()
        : [];

    const groupedByEmployee = aggregatedRecords.reduce((acc, record) => {
        const key = `${record.firstName} ${record.lastName}`;
        if (!acc[key]) {
            acc[key] = [];
        }
        acc[key].push(record);
        return acc;
    }, {});

    return (
        <>
            {detailedView ? (
                <DetailedViewPage
                    employeeRecords={detailedView?.employeeRecords}
                    setDetailedView={setDetailedView}
                    payPeriod={payPeriod}
                />
            ) : (
                !isMobile && !isLandscape ? (
                    <ThemeTable
                        rows={rows}
                        headCells={columns}
                        hidePagination={true}
                        isLoading={isLoading}
                        renderRowCell={renderRowCell}
                        extraHeader={extraHeader}
                    />
                ) : (
                    payPeriod && payPeriod !== "None" ? (
                        <Card className={`${styles.card3}`}>
                            <CardHeader
                                title={(
                                    <div className={styles.date}>{selectedPayPeriodOption.label}</div>
                                )}
                                className={styles.cardHeader}
                            />
                            <CardContent className={styles.cardContent}>
                                <List>
                                    {Object.keys(groupedByEmployee).map((employee, date, index) => (
                                        <React.Fragment key={index}>
                                            <ListItem className={styles.listItem} >
                                                <ListItemText
                                                    primary={(
                                                        <div className={styles.title1}>
                                                            <span> {`${employee}`} </span>
                                                            <span> {`${groupedByEmployee[employee][0].total} Hrs.`}</span>
                                                        </div>
                                                    )}
                                                />
                                                <KeyboardArrowRightSharpIcon onClick={() => handleViewDetails(date, groupedByEmployee[employee])} />
                                            </ListItem>
                                            <Divider className={` ${styles.divider}`}/>
                                        </React.Fragment>
                                    ))}
                                </List>
                            </CardContent>
                            <CardActions className={styles.cardActions}>
                                <Typography variant="body2"> Actions </Typography>
                                <div className={styles.icons}>
                                    <FontAwesomeIcon icon={faEnvelope} size='lg' onClick={() => onClickEmail(aggregatedRecords)} />
                                    <FontAwesomeIcon icon={faFilePdf} size='lg' onClick={() => onClickPdf(aggregatedRecords)} />
                                    <CSVLink
                                        data={clientsListCSV}
                                        filename={`${fileName}_${selectedPayPeriodOption.label}.csv`}
                                        asyncOnClick={true}
                                        onClick={(event, done) => onClickCsv(event, done, aggregatedRecords)}
                                    >
                                        <FontAwesomeIcon icon={faFileCsv} size='lg' className='color-primary' />
                                    </CSVLink>
                                    <FontAwesomeIcon icon={faFileExcel} size='lg' onClick={() => onClickExcel(aggregatedRecords)} />
                                </div>
                            </CardActions>
                        </Card>
                    ) : (
                        sortedDateKeys.map((date) => (
                            <Card key={date} className={` ${styles.card3}`}>
                                <CardHeader
                                    title={moment(date).format("MMM DD, YYYY")}
                                    className={styles.cardHeader}
                                />
                                <CardContent className={styles.cardContent}>
                                    <List>
                                        {groupedByDate[date].map((employeeRecord, index) => (
                                            <React.Fragment key={`${employeeRecord.id}-${index}`}>
                                                <ListItem className={styles.listItem}>
                                                    <ListItemText
                                                        primary={(
                                                            <div className={styles.title1}>
                                                                <span> {`${employeeRecord.firstName} ${employeeRecord.lastName}`} </span>
                                                                <span> {`${getDateValue(employeeRecord, date)}`}</span>
                                                            </div>
                                                        )}
                                                    />
                                                    <KeyboardArrowRightSharpIcon
                                                        onClick={() => handleViewDetails(date, [employeeRecord])}
                                                    />
                                                </ListItem>
                                                <Divider className={` ${styles.divider}`}/>
                                            </React.Fragment>
                                        ))}
                                    </List>
                                </CardContent>
                                <CardActions style={{ justifyContent: "space-between" }} className={styles.cardActions}>
                                    <Typography variant="body2">Actions</Typography>
                                    <div className={styles.icons}>
                                        <FontAwesomeIcon icon={faEnvelope} size='lg' onClick={() => onClickEmail(groupedByDate[date], date)} />
                                        <FontAwesomeIcon icon={faFilePdf} size='lg' onClick={() => onClickPdf(groupedByDate[date], date)} />
                                        <CSVLink
                                            data={clientsListCSV}
                                            filename={`${fileName}_${date}.csv`}
                                            asyncOnClick={true}
                                            onClick={(event, done) => onClickCsv(event, done, groupedByDate[date], date)}
                                        >
                                            <FontAwesomeIcon icon={faFileCsv} size='lg' className='color-primary' />
                                        </CSVLink>
                                        <FontAwesomeIcon icon={faFileExcel} size='lg' onClick={() => onClickExcel(groupedByDate[date], date)} />
                                    </div>
                                </CardActions>
                            </Card>
                        ))
                    )
                )
            )}
        </>
    );
};

const DetailedViewPage = ({ employeeRecords, setDetailedView, payPeriod }) => {
    const totalHours = employeeRecords[0].total;

    const formattedDate = date => moment(date).format("MM/DD (ddd)");

    const recordsByDate = employeeRecords.reduce((acc, record) => {
        const date = record.date;
        if (!acc[date]) {
            acc[date] = [];
        }
        acc[date].push(record);
        return acc;
    }, {});

    const sortedDates = Object.keys(recordsByDate).sort();

    return (
        <>
            <Card className={`${styles.card4}`}>
                <CardHeader
                    title={(
                        <div className={styles.title}>
                            <span>{employeeRecords[0].name}</span>
                            {payPeriod === "None" ? (
                                <span className={styles.title2}>Total Hrs: {recordsByDate[sortedDates[0]][0][sortedDates[0]] || 0}</span>
                            ) : (
                                <span className={styles.title2}>Total Hrs: {totalHours || 0}</span>
                            )}

                        </div>
                    )}
                    className={styles.cardHeader2}
                />
                <CardContent className={styles.cardContent2}>
                    {sortedDates.map(date => (
                        <div key={date} className='p-10'>
                            <Typography className={styles.dateHeader}>
                                {formattedDate(date)}
                            </Typography>
                            {recordsByDate[date].map((record, index) => (
                                <div key={index} className={styles.recordRow}>
                                    <div className={styles.recordValue}>
                                        {record[date] || 0}
                                    </div>
                                    <div className={styles.popupData}>
                                        {record.popupData[date]?.map((popup, idx) => (
                                            <div key={idx} className={styles.popupItem}>
                                                <div><span>Shift: </span>{popup.shiftName  || '----'}</div>
                                                <div><span>In: </span>{popup.In  || '----'}</div>
                                                <div><span>Out: </span> {popup.Out  || '----'}</div>
                                            </div>
                                        )) || (
                                            <div className={styles.popupItem}>
                                                <div><span>Shift: </span>----</div>
                                                <div><span>In: </span>----</div>
                                                <div><span>Out: </span>----</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            ))}
                            {payPeriod === "None" && (
                                <Typography variant="h6" className={styles.totalRow}>
                                    Total: {recordsByDate[date][0][date] || 0}
                                </Typography>
                            )}
                        </div>
                    ))}
                    {payPeriod !== "None" && (
                        <div className={styles.totalRow}>
                            <Typography variant="h6">
                                Total : {totalHours || 0}
                            </Typography>
                        </div>
                    )}
                </CardContent>
            </Card>
            <Button
                className={styles.button2}
                size="small"
                variant="contained"
                color="primary"
                onClick={() => setDetailedView(null)}
            >
                BACK
            </Button>
        </>
    );

};

export default ResponsiveTable;
