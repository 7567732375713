import React, { useEffect, useContext, useState } from "react";
import { Grid, Typography, TextField, Popover, IconButton, DialogContent, DialogContentText, Dialog, DialogTitle, Button, DialogActions } from "@material-ui/core";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { useNavigate } from "react-router-dom";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import ThemeTable from "../../../components/Table/ThemeTable";
import { getEmployeesListBasedOnId } from "../../../services/apiservices/employee";
import styles from "../dashboard.module.scss";
import { getDocCount } from "../../../services/apiservices/counters";
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { COLLECTIONS } from "../../../services/config";
import moment from 'moment';
import { FormatedDates } from "../../../services/helpers";
import ConfirmationDialog from "../../../components/DialogComponent/ConfirmationDialog";
import { getCategories, supplyRejectReasons, updateSuppliesStatus, updateSupplyItemsStatus } from "../../../services/apiservices/supplies";
import { saveNotification2 } from "../../../services/apiservices/notifications";
import CommonContext from "../../../hooks/commonContext";
import { Link } from 'react-router-dom';
import confirmn from "../../../components/DialogComponent/confirmn";
import { ConfirmationsDialog, SuccessDialog, SuppplyDataDialog } from "../../../components/DialogComponent/SupplyDataDialog";
import { useMediaQuery } from "@mui/material";
import SupplyCommonCard from "../../../components/ResponsiveCardComponent/SupplyComponent/SupplyListPageResponsive";


const Columns = [
  {
    id: "employeeName",
    numeric: false,
    disablePadding: false,
    label: "Name",
    isSortable: true,
  },
  {
    id: "item",
    numeric: false,
    disablePadding: false,
    label: "Item",
    isSortable: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: true,
    label: "Status",
    isSortable: true,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "Date",
    isSortable: true,
  },

  {
    id: "viewlink",
    numeric: false,
    isEditRow: true,
    disablePadding: true,
    label: "Action",
    rowclassName: "",
    className: "pr-30",
  },
];

const ColumnsRes = [
  {
    id: "priority",
    numeric: false,
    disablePadding: true,
    label: "Status",
    isSortable: true,
  },
  {
    id: "date",
    numeric: false,
    disablePadding: true,
    label: "Date",
    isSortable: true,
  },
  {
    id: "item",
    numeric: false,
    disablePadding: false,
    label: "Item",
    isSortable: true,
  },
];

const CustomTooltip = ({ listData, data, title }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <div className="d-flex flex-direction-row flex-center">
        <div className="flex-space-around">{data}</div>
        <Typography
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
          style={{
            cursor: "pointer",
            color: "blue",
            marginLeft: "8px"
          }}
        >{title}
        </Typography>
      </div>
      <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="tooltip-heading2 p-5">
          <div className="tooltip-container2">
            {Array.isArray(listData) ? (
              <div>
                {listData.map((item, index) => (
                  <div key={index}>
                    <div>{item.title} ({item.qty})</div>
                  </div>
                ))}
              </div>
            ) : (
              <div>{listData}</div>
            )}
          </div>
        </div>
      </Popover>
    </div>
  );
};

function EmployeeSummary(props) {
  const [sort, setSort] = useState("Newest");
  const commonContext = useContext(CommonContext);
  const [employeeList, setEmployeeList] = useState();
  const [filterDate, setFilterDate] = useState(moment().format("YYYY-MM-DD"));
  const [selectedReason, setSelectedReason] = useState('');
  const [reasonMessage, setReasonMessage] = useState('');
  const [recordID, setRecordID] = useState();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);
  const [data, setData] = useState();
  const [empData, setEmpData] = useState();
  const [categories, setCategories] = useState({});
  const [categoryMap, setCategoryMap] = useState({});
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
  const [expanded, setExpanded] = useState(null);
  const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false);
  const [openSupplyDialog, setOpenSupplyDialog] = useState(false);
  const [openData, setOpenData] = useState(null);
  const [dialogOpenSucces, setDialogOpenSucces] = useState(false);
  const [opens, setOpens] = useState(null);
  const history = useNavigate();


  const handleDateChange = (newValue) => {
    setFilterDate(newValue.target.value);
  };

  const sortBy = [
    { label: "Newest", value: "Newest" },
    { label: "Oldest", value: "Oldest" },
  ];

  const onChangeInput = (e) => {
    setSort(e.target.value);
  };

  const getEmployeeSummaryData = async () => {
    getDocCount(
      COLLECTIONS.SUPPLIES_ORDER,
      async (res) => {

        let filterRecords = res.filter((item) => item.archive !== true);

        let empIds = filterRecords.map((orderItem) => orderItem.createdBy);
        empIds = [...new Set(empIds)]

        let employeeData = [];
        if (empIds.length > 0) {
          await getEmployeesListBasedOnId(empIds, (result) => {
            const employees = result.map((item) => {
              return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}`, email: item?.email };
            });
            setEmpData(employees);
            employeeData = employees;
          });
        }

        // let recordsWithMaxNumberAllowed = res.filter(record => 
        //   record.supplyItems.some(item => item.maxNumberAllowed !== undefined)
        // );

        // let recordsWithoutMaxNumberAllowed = res.filter(record =>
        //   record.supplyItems.every(item => !item.hasOwnProperty('maxNumberAllowed'))
        // );

        let finaleData = filterRecords.map((ritem) => {
          const employee = employeeData.find(emp => emp?.value === ritem?.createdBy);
          const employeeName = employee ? employee.label : 'Unknown employee';

          let hasMaxNumberAllowed = ritem.supplyItems.some(item => item.maxNumberAllowed !== undefined);

          let totalQuantity = 0;

          if (ritem?.supplyItems) {
            ritem.supplyItems.forEach((item) => {
              totalQuantity += item.qty || 0;
            });
          }

          return {
            ...ritem,
            orderId: String(ritem?.orderNumber).padStart(6, '0'),
            employeeName: employeeName,
            item: hasMaxNumberAllowed ? 'Clothing' : 'Supplies',
            totalQuantity: `(${totalQuantity})`,
            dateOfRequest: ritem?.dateOfRequest,
          };
        });

        let sortedData = sortingFunction(finaleData).sort((a, b) => new Date(b.dateOfRequest) - new Date(a.dateOfRequest));

        // let sortedData = sortingFunction(tempData);

        let latestData = sortedData.slice(0, 10);
        await fetchAndStoreCategories(latestData);
        setEmployeeList(latestData);

      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
  }

  useEffect(() => {
    getEmployeeSummaryData();
  }, [])

  const sortingFunction = (data) => {
    const result = data.sort((a, b) => {
      if (a.dateOfRequest < b.dateOfRequest) return -1;
      if (a.dateOfRequest > b.dateOfRequest) return 1;

      if (a.employeeName < b.employeeName) return -1;
      if (a.employeeName > b.employeeName) return 1;

      return 0;
    });

    return result;
  };

  const getviewTag = (cellData, id) => {
    return (
      <a
        className='link2'
        href={cellData['viewlink']}
        onClick={(e) => {
          e.preventDefault();
          history("/supplies/cleaning/manage/" + id);
        }}
      >
        View
      </a>
    );
  }

  const fetchAndStoreCategories = async (latestData) => {
    const newCategoryMap = {};
    for (const item of latestData) {
      const { id: mainId, supplyItems } = item;
      if (supplyItems) {
        const categoriesForItem = [];
        for (const supplyItem of supplyItems) {
          const { parentCategoryId } = supplyItem;
          if (parentCategoryId) {
            await getCategories(parentCategoryId, (categoriesData) => {
              const category = categoriesData.find((cate) => cate?.id === parentCategoryId);
              const title = category?.title;
              if (title) {
                categoriesForItem.push({
                  parentCategoryId,
                  title
                });
                setCategories((prevCategories) => ({
                  ...prevCategories,
                  [parentCategoryId]: title,
                }));
              }
            });
          }
        }
        newCategoryMap[mainId] = categoriesForItem;
      }
    }
    setCategoryMap(newCategoryMap);
  };

  const sendRejectNotification = async () => {
    let name;
    let email;
    const orderId = data ? data.orderId : null;
    const clientName = data ? data.clientName : null;
    const supply = true;

    const employee = empData.find(emp => emp.value === data.createdBy);
    if (employee) {
      name = employee.label;
      email = employee.email;
    }

    let updatedTitles = [];
    const category = categoryMap[data.id];
    if (category) {
      updatedTitles = data.supplyItems.map((supplyItem, index) => {
        const categoryItem = category.find(cat => cat.parentCategoryId === supplyItem.parentCategoryId);
        const title = categoryItem ? `${categoryItem.title} - ${supplyItem.title}` : `${supplyItem.title}`;
        return `${index + 1}. ${title} (${supplyItem.qty})`;
      });
    }

    const updatedTitlesStr = updatedTitles.join('\n');

    const notifications = [
      {
        Type: "PushNotification",
        Message: `Your supply request OrderID ${data.orderId} has been cancelled. Please check your email.`,
        read: false,
        Employee: [
          {
            id: data?.createdBy,
            name: data?.employeeName
          }
        ]
      },
      {
        Type: "Email",
        Message: `Your supply request OrderId ${data.orderId} has been canceled by the support team.\n` +
                 `Please contact us to resubmit a new request or discuss this order with your support team.\n\n` +
                 `Details:\n` +
                 `${updatedTitlesStr}`,
        read: false,
        Employee: [
          {
            id: data?.createdBy,
            name: name,
            email: email
          }
        ]
      }
    ];

    await saveNotification2(notifications, supply, orderId, clientName);
  };

  const handleCloseSuccesDialog = async () => {
    setDialogOpenSucces(false);
    setOpens(null);
    await getEmployeeSummaryData();
  };

  const handleDialogOpen = (item) => {
    setSelectedItem(item);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem(null);
    setSelectedReason('');
    setReasonMessage('');
  };

  const handleProceed = async () => {

    if (!selectedReason && !reasonMessage) {
      setAlertOpen(true);
      return;
    }

    const result = {
      reason: selectedReason,
      message: reasonMessage,
    };

    commonContext?.setLoader(true);

    try {
      const formData = {
        status: "Rejected"
      };

      const promises = [
        supplyRejectReasons(recordID, result),
        updateSuppliesStatus(recordID, formData),
        updateSupplyItemsStatus(recordID, "Cancel Item"),
        sendRejectNotification()
      ];

      handleDialogClose();

      await Promise.all(promises);

      setSelectedReason('');
      setReasonMessage('');
      await getEmployeeSummaryData();
      commonContext?.setLoader(false);
    } catch (error) {
      console.error("Error handling proceed:", error);
    }
  };

  const approvedClick = async (id) => {
    const formData = {
      status: "Approved"
    }
    await updateSuppliesStatus(id, formData);
    setDialogConfirmOpen(false);
    setSelectedItem(null);
    setDialogOpenSucces(true);
  }

  const handelApproveClick = async (id) => {
    setDialogConfirmOpen(true);
    setSelectedItem(id);
  }

  const handleDialogConfirmClose = () => {
    setDialogConfirmOpen(false);
    setSelectedItem(null);
  };

  const getApprovedReject = (cellData) => {
    return (
      <>
        <IconButton
          className="color-primary mr-10 empSummary"
          onClick={() => handelApproveClick(cellData?.id)}
        >
          <CheckCircleOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={() => {
            handleDialogOpen(cellData?.id);
            setRecordID(cellData?.id);
            setData(cellData);
          }}
          className="empSummary"
        >
          <CancelOutlinedIcon className="color-danger" />
        </IconButton>
      </>
    );
  }

  const getStatusTag = (cellData) => {
    let status = styles.statusYellow;
    let statusColor = styles.statusYellowcolo;
    if (cellData['priority'] === '1') {
      status = styles.statusRed;
      statusColor = styles.statusRedcolo;
    }
    return <div className={`${statusColor}`}><div className={`${styles.stausCircle} ${status}`}></div>{getPriorityLabel(cellData['priority'])}</div>
  }

  const handleDialogSupplyOpen = async (data) => {
    setOpenSupplyDialog(true);
    setOpenData(data);
  };

  const handleDialogSupplyClose = async () => {
    setOpenSupplyDialog(false);
    setOpenData(null);
  };

  const getPriorityLabel = (priority) => {
    switch (priority) {
      case "1":
        return "High";
      case "2":
        return "Medium";
      case "3":
        return "Low";
      default:
        return "Unknown";
    }
  };

  const renderRow = (cellData, colData, cellIndex, RowIndex) => {
    if (cellIndex === 0) {
      return <p>{cellData[colData.id]}</p>;
    }
    else if (cellIndex === 1) {
      return (
        <>
          <CustomTooltip
            listData={cellData?.supplyItems}
            data={cellData?.item}
            title={cellData?.totalQuantity}
          />
        </>
      )
    }
    else if (cellIndex === 2) {
      return getStatusTag(cellData);
    }
    else if (cellIndex === 3) {
      return FormatedDates(cellData?.dateOfRequest);
    }
    else if (cellIndex === 4) {
      if (cellData?.status !== "Pending")
        return getviewTag(cellData, cellData.id);
      else {
        return getApprovedReject(cellData)
      }
    }
    return cellData[colData.id] ? cellData[colData.id] : '';
  }

  const handleExpandClick = (id) => {
    setExpanded(prevExpanded => (prevExpanded === id ? null : id));
  };

  const handelTooltipClicks = (id) => {
    setOpens(opens === id ? null : id);
  }

  const redirectToDetails = (id) => {
    if (typeof id !== "undefined" && id !== "")
      history("/supplies/cleaning/manage/" + id);
  };

  return (
    <>
      <Grid container alignItems="center" spacing={2} className="mb-10">
        <Grid item xs={12} sm={6}>
          <Typography variant="h6">
            <PersonOutlineOutlinedIcon className="color-primary mr-10 svg-icon" />
            Supply Summary
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="d-flex w320 float-right">
            <div className="mh-12">
              <TextField
                name="date"
                value={filterDate}
                fullWidth={true}
                size="small"
                variant="outlined"
                className={`field w-100`}
                type={"date"}
                inputProps={{
                  className: "p-10",
                  min: moment().add(-30, "days").format("YYYY-MM-DD"),
                  max: moment().add(15, "days").format("YYYY-MM-DD"),
                }}
                onChange={handleDateChange}
              />
            </div>
            <div className="mh-12 clientsummry">
              <SingleSelect
                value={sort}
                className={`field w-100`}
                name={"sort"}
                onChange={(e) => onChangeInput(e)}
                options={sortBy}
              />
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={`sectionBox nospacing tabletd shadow ${styles.summaryTableWrap}`}>
        {(!isMobile && !isLandscape) &&
          <ThemeTable
            rows={employeeList}
            headCells={Columns}
            hidePagination={true}
            renderRowCell={renderRow}
          />
        }
        {(isMobile || isLandscape) && (
          <>
            {(employeeList?.length > 0 ? employeeList : []).map((record, index) => {
              const isOpen = expanded === record?.id;
              return (
                <SupplyCommonCard
                  key={record?.id}
                  index={index}
                  record={record}
                  isOpen={isOpen}
                  redirectToDetails={redirectToDetails}
                  handleExpandClick={handleExpandClick}
                  getPriorityLabel={getPriorityLabel}
                  handelTooltipClicks={handelTooltipClicks}
                  opens={opens}
                  handelApproveClick={handelApproveClick}
                  handleDialogOpen={handleDialogOpen}
                  setRecordID={setRecordID}
                  getStatusTag={getStatusTag}
                  setData={setData}
                  handleDialogSupplyOpen={handleDialogSupplyOpen}
                  columns={ColumnsRes}
                  SupplySummary={true}
                />
              );
            })}
          </>
        )}
        <p className="text-center">
          <Link className="link" to="/supplies/cleaning/manage">View all Supply Summary</Link></p>
      </div>
      {dialogOpen && (
        <ConfirmationDialog
          show={dialogOpen}
          title="Reason to Reject Supply"
          confirmation={
            <>
              <SingleSelect
                select
                label="Reason"
                fullWidth
                margin="normal"
                value={selectedReason}
                onChange={(e) => setSelectedReason(e.target.value)}
                options={[
                  { label: "Recent Shipment", value: "Recent Shipment" },
                  { label: "Duplicate Request ", value: "Duplicate Request" },
                  { label: "Contact Management", value: "Contact Management" }
                ]}
              />
              <TextField
                fullWidth
                multiline={true}
                label="Reason Message"
                variant="outlined"
                placeholder="Reason Message"
                rows={3}
                margin="normal"
                value={reasonMessage}
                onChange={(e) => setReasonMessage(e.target.value)}
              />
            </>
          }
          proceed={handleProceed}
          dismiss={handleDialogClose}
          cancel={handleDialogClose}
        />
      )}

      <Dialog
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
      >
        <DialogTitle>Missing Information</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide a reason or a message before proceeding.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAlertOpen(false)} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      {dialogConfirmOpen && (
        <ConfirmationsDialog
          open={dialogConfirmOpen}
          onConfirm={() => approvedClick(selectedItem)}
          onClose={handleDialogConfirmClose}
        />
      )}
       <SuccessDialog open={dialogOpenSucces} onClose={handleCloseSuccesDialog} />
      <SuppplyDataDialog open={openSupplyDialog} onClose={handleDialogSupplyClose} listData={openData} />
    </>
  );
}

export default EmployeeSummary;
