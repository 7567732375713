import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Grid,
  Container,
  TextareaAutosize,
  Button,
  Tooltip,
  styled,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import ModalImage from "react-modal-image";
import CircularProgress from "@mui/material/CircularProgress";

import Resizer from "react-image-file-resizer";
import firebase from "firebase";
import PageTitle from "../../components/pageTitle/PageTitle";
import SendIcon from "@mui/icons-material/Send";
import PlusOneSharp from "@mui/icons-material/Add";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import PermContactCalendarOutlinedIcon from "@mui/icons-material/PermContactCalendarOutlined";
import ClearIcon from "@mui/icons-material/Clear";

import {
  getChannelList,
  sendMessageToChannel,
  updateChannel,
  getMessages,
} from "../../services/apiservices/messages";

import AuthContext from "../../hooks/authContext";
import moment from "moment";

const ChatLeftPannel = () => {
  const auth = useContext(AuthContext);

  const [pvtChannels, setPVTChannels] = React.useState([]);
  const [pubChannels, setPUBChannels] = React.useState([]);

  useEffect(() => {
    const unsubscribe = getChannelList(
      {},
      (res) => {
        if (res) {
          const pvtChn = [];
          const pubChn = [];
          let isReadPriv = true;
          let isReadPubl = true;
          res.forEach((element) => {
            if (element.type === "Private") {
              if (isReadPriv) {
                isReadPriv =
                  element?.readMsgUsers?.includes(auth.userProfile.id) ||
                    element?.id === auth.currentChannel?.id
                    ? true
                    : false;
              }
              pvtChn.push(element);
            } else {
              pubChn.push(element);
              if (isReadPubl) {
                isReadPubl =
                  element?.readMsgUsers?.includes(auth.userProfile.id) ||
                    element?.id === auth.currentChannel?.id
                    ? true
                    : false;
              }
            }
          });

          auth.setIsReadPublic(isReadPubl);
          auth.setIsReadPrivate(isReadPriv);
          setPUBChannels(pubChn);
          setPVTChannels(pvtChn);
        }
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
    // return unsubscribe; 
    // fetchChannelList();
  }, [auth]);
  const onChannelClickHandler = (channel) => {
    auth.setCurrentChannel(channel);
  };
  return (
    <>
      <Accordion className="chat-accordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="chanelHeader">Private Channel</div>
          {!auth.isReadPrivate && <div className="msgbadge"></div>}
        </AccordionSummary>
        <AccordionDetails>
          {pvtChannels.map((item, index) => (
            <ChanelItem
              currentUserId={auth.userProfile.id}
              key={item.id}
              item={item}
              currChannel={auth.currentChannel}
              onClickHandler={onChannelClickHandler}
            />
          ))}
        </AccordionDetails>
      </Accordion>
      <Accordion className="chat-accordian">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <div className="chanelHeader">Public Channel</div>
          {!auth.isReadPublic && <div className="msgbadge"></div>}
        </AccordionSummary>
        <AccordionDetails>
          {pubChannels.map((item, index) => (
            <ChanelItem
              currentUserId={auth.userProfile.id}
              key={item.id}
              item={item}
              currChannel={auth.currentChannel}
              onClickHandler={onChannelClickHandler}
            />
          ))}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
const ChanelItem = ({ item, currChannel, onClickHandler, currentUserId }) => {
  return (
    <div
      className={`channleTitleBox ${currChannel?.id === item.id ? "active" : ""
        }`}
      onClick={() => {
        onClickHandler(item);
      }}
    >
      <div className="channelTitle">{item.groupName}</div>
      <div className="channelMessage">
        {item?.last_message?.length > 40
          ? `${item?.last_message.substring(0, 40)}...`
          : item?.last_message}
      </div>
      {!item?.readMsgUsers?.includes(currentUserId) &&
        item?.id !== currChannel?.id ? (
        <div className="msgbadge"></div>
      ) : null}
    </div>
  );
};

const SendTextMessageBox = ({ currChannel }) => {
  const imgRef = useRef(null);
  const auth = useContext(AuthContext);
  const [msgText, setMsgText] = useState("");
  const [currentImage, setCurrentImage] = useState();
  const [displayImage, setDisplayImage] = useState([]);
  // const [compressImage, setCompressImage] = useState([]);
  const [imageLink, setImageLink] = useState([]);

  const onChangeTextHandler = (eve) => {
    setMsgText(eve.target.value);
  };

  const sendMessage = () => {
    const message = {
      channelId: currChannel.id,
      sender: {
        userId: auth.userProfile.id,
        firstName: auth.userProfile.firstName,
        lastName: auth.userProfile.lastName,
      },
      type: currentImage !== "" ? "image" : "text",
      readBy: [],
      message: msgText,
      image: imageLink ? imageLink : "",
      cimage: displayImage ? displayImage : "",
    };
    let empFound = currChannel.employee.find(
      (emp) => emp.id === auth.userProfile.id
    );

    if (!empFound) {
      const emp = {
        id: auth.userProfile.id,
        firstName: auth.userProfile.firstName,
        lastName: auth.userProfile.lastName,
        email: auth.userProfile.email,
        phone: auth.userProfile.phoneNumber,
      };
      if (currChannel.employee) {
        currChannel.employee.push(emp);
      } else {
        currChannel.employee = [emp];
      }
    }
    setMsgText("");
    currChannel.last_message = msgText;
    currChannel.readMsgUsers = [auth.userProfile.id];
    const channelUpdates = Object.assign({}, currChannel);
    updateChannel(currChannel.id, channelUpdates);
    sendMessageToChannel(
      message,
      (res) => {
        removeSelectedImage(0, false);
      },
      (err) => {
        console.log(err);
      }
    );
  };
  const uploadPhoto = (currentFile, files, index, imgLink = []) => {
    const timestamp = moment().valueOf().toString();

    const uploadTask = firebase
      .storage()
      .ref()
      .child(timestamp)
      .put(currentFile);

    uploadTask.on(
      "state_changed",
      null,
      (err) => {
        console.log(err);
        // this.props.showToast(0, err.message)
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          imgLink.push(downloadURL);
          // console.log(imgLink);
          setImageLink(imgLink);

          if (files.length > (index + 1)) {
            uploadPhoto(files[index + 1], files, index + 1, imgLink);
          } else {
            return;
          }

          // console.log(downloadURL);

          // if(imageLink.length > 0){}
          // setImageLink((prev) => (prev) ? `${prev}|||${downloadURL}`: downloadURL);
          // console.log(downloadURL);
        });
      }
    );
  };

  const resizeImage = (files, index, callbackAfterFinish, disImages = []) => {

    const prefixFiletype = files[index].type.toString();
    if (prefixFiletype.indexOf("image/") === 0) {
      // setImageLink("");

      try {
        Resizer.imageFileResizer(
          files[index],
          200,
          200,
          "JPEG",
          50,
          0,
          (uri) => {
            // const disImage = [...displayImage];
            disImages.push(uri);
            // console.log(disImage);
            // setDisplayImage(disImages);

            if (files.length > (index + 1)) {

              // const disImage = [...displayImage];
              //   disImage.push(uri);
              // console.log(disImage);
              setDisplayImage(disImages);
              resizeImage(files, index + 1, callbackAfterFinish, disImages);
            } else {
              callbackAfterFinish(disImages, 1);
            }
          },
          "base64",
          100,
          100
        );
      } catch (err) {
        callbackAfterFinish(err, 0);
        console.log(err);
      }
    } else {
      if (files.length > (index + 1)) {
        resizeImage(files, index + 1, callbackAfterFinish, disImages);
      } else {
        callbackAfterFinish(disImages, 1);
      }
      // this.setState({isLoading: false})
      console.log("This file is not an image");
    }

  }
  const onChoosePhoto = (event) => {
    if (event.target.files && event.target.files[0]) {
      setCurrentImage(event.target.files[0]);
      const FilesArr = [];

      for (let fileKey in event.target.files) {
        const file = event.target.files[fileKey];

        if (file?.type) {
          FilesArr.push(file);

          // const prefixFiletype = file.type.toString();
          // if (prefixFiletype.indexOf("image/") === 0) {
          //   // setImageLink("");
          //   uploadPhoto(file);
          //   try {
          //     Resizer.imageFileResizer(
          //       file,
          //       200,
          //       200,
          //       "JPEG",
          //       50,
          //       0,
          //       (uri) => {
          //         const disImage = [...displayImage];
          //         disImage.push(uri);
          //         console.log(disImage);
          //         setDisplayImage(disImage);
          //       },
          //       "base64",
          //       100,
          //       100
          //     );
          //   } catch (err) {
          //     console.log(err);
          //   }
          // } else {
          //   // this.setState({isLoading: false})
          //   console.log("This file is not an image");
          // }
        }
      }
      if (FilesArr.length > 0) {

        for (let index = 0; index < FilesArr.length; index++) {

          resizeImage(FilesArr, index, (disImages, isSuccess) => {
            setDisplayImage(disImages);
            uploadPhoto(FilesArr[index], FilesArr, index, [...imageLink]);

          }, [...displayImage]);
        }
      }

    } else {
      // this.setState({isLoading: false})
    }
  };
  const removeSelectedImage = (index, isDelete = true) => {
    if (isDelete) {
      if (imageLink.length > 0 && imageLink[index]) {
        const fileRef = firebase.storage().refFromURL(imageLink[index]);
        const imgLink = [...imageLink];
        imgLink.splice(index, 1);
        setImageLink(imgLink);
        const disImage = [...displayImage];
        disImage.splice(index, 1);
        setDisplayImage(disImage);
        fileRef
          .delete()
          .then(function () {
            console.log("File Deleted");
          })
          .catch(function (error) {
            // Some Error occurred
            console.log(error);
          });
      }
    } else {
      setImageLink([]);
      setDisplayImage([]);
    }
    imgRef.current.value = "";
  };
  const checkSendIsDisbaled = () => {
    if (currentImage !== "" && imageLink.length > 0) {
      return false;
    } else if (currentImage === "" && imageLink.length === 0) {
      return msgText.trim() === "";
    } else if (msgText.trim().length > 0) {
      return false;
    }
    return true;
  };
  return (
    <div className="msgBoxsender" key="msgBoxsender">
      {displayImage.length > 0 ? (
        <div className="selectedImage" key="selectedImage">
          {displayImage?.map((item, index) => {

            return (
              <div className="imgBox" key={`${new Date().toISOString()}${index}`}>
                <img src={`${item}`} height={50} width={50} alt={item} />
                <ClearIcon
                  className="imgBoxClearIcon"
                  onClick={() => {
                    removeSelectedImage(index);
                  }}
                />
                {!imageLink[index] && (
                  <div className="progressBar">
                    <CircularProgress size={20} />
                  </div>
                )}
              </div>
            );
          })}
          <div key={"addbutton"}>
            <Button
              variant="contained"
              className="flatbutton addmoreimg"
              onClick={() => {
                imgRef.current.click();
              }}
            >
              <PlusOneSharp color="#ffffff" />
            </Button>
          </div>
        </div>
      ) : null}
      {/* // (
      //   <div className="selectedImage">
      //     <div className="imgBox">
      //       <img src={`${displayImage}`} height={50} width={50} />
      //       <ClearIcon
      //         className="imgBoxClearIcon"
      //         onClick={removeSelectedImage}
      //       />
      //       {imageLink === "" && (
      //         <div className="progressBar">
      //           <CircularProgress size={20} />
      //         </div>
      //       )}
      //     </div>
      //     <div>
      //     <Button
      //     variant="contained"
      //     className="flatbutton addmoreimg"
      //     onClick={()=>{
      //       imgRef.current.click();
      //     }}
      //   >
      //     <PlusOneSharp color="#ffffff" />
      //   </Button>
      //     </div>
      //   </div>
      // )} */}
      <TextareaAutosize
        key="autoresizetext"
        minRows={5}
        className="msgTextbox"
        placeholder="Type here to send message..."
        value={msgText}
        onChange={onChangeTextHandler}
        maxLength={300}
      />
      <div className="buttonContainer" key="buttonContainer">
        <Button
          variant="contained"
          className="flatbutton sendbtn"
          onClick={sendMessage}
          disabled={checkSendIsDisbaled()}
        >
          <SendIcon color="#ffffff" />
        </Button>
        <input
          ref={imgRef}
          accept="image/*"
          className="viewInputGallery"
          type="file"
          multiple
          onChange={onChoosePhoto}
        />

        <Button
          variant="contained"
          className="flatbutton imgbtn"
          onClick={() => {
            imgRef.current.click();
          }}
        >
          <AddPhotoAlternateOutlinedIcon />
        </Button>
      </div>
    </div>
  );
};

const getNameCharcters = (user) => {
  return `${user?.firstName[0]}${user?.lastName[0]}`;
};
const MessageElement = ({ message, onMSGItemClick }) => {
  return (
    <>
      <div className="nameBox">{getNameCharcters(message.sender)}</div>
      <div className="msgTextBox">
        <div className="dtDisplay">
          {`${message.sender.firstName} ${message.sender.lastName}, ${moment(
            message.updatedOn
          ).format("hh:mm a")}`}{" "}
        </div>
        {message?.imagesObj?.length > 0 &&
          message?.imagesObj?.map((element, index) => {
            return (<>
              {element.type === "image" &&
                element.image !== "" &&
                typeof element.cimage === 'string' &&
                element?.cimage.length > 0 && (
                  <div className="imageDisplay">
                    <div>
                      <ModalImage
                        small={element.cimage}
                        large={element.image}
                        alt={element.message}
                        showRotate
                      />
                    </div>

                    {/* <img
                      src={message.cimage}
                      height={60}
                      onClick={() => {
                        onMSGItemClick(message);
                      }}
                    /> */}
                  </div>
                )}
              {element.type === "image" &&
                element.image !== "" &&
                typeof element.cimage === 'object' &&
                element?.cimage.length > 0 &&
                element?.cimage.map((item, index) => {
                  return (
                    <div className="imageDisplay">
                      <div>
                        <ModalImage
                          small={item}
                          large={item}
                          alt={element.message}
                          showRotate
                        />
                      </div>

                      {/* <img
                        src={message.cimage}
                        height={60}
                        onClick={() => {
                          onMSGItemClick(message);
                        }}
                      /> */}
                    </div>
                  )
                })
              }
            </>)
          })
        }
         <div className="textDisplay">{message.message}</div>
      </div>
    </>
  );
};
const DisplayMessagesBox = ({ currChannel }) => {
  const [messages, setMessages] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [allMessages, setAllMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(-10);
  // const [isLoadIMGPopup, setIsLoadIMGPopup] = useState(false);
  // const [largeImageURL, setLastImageURL] = useState("");
  const bottomRef = useRef(null);
  // const topRef = useRef(null);

  const auth = useContext(AuthContext);

  useEffect(() => {
    const unsubscribe = getMessages(
      currChannel.id,
      (res) => {
        if (res) {
          
          // const msgs = res.filter((ite) => ite.channelId == auth.currentChannel.id)
          setMessages(res.slice(messageCount));
          setAllMessages(res);
          setTimeout(() => {
            setIsLoaded(true);
          }, 2000);
        }
      },
      (resError) => {
        console.log("reserror", resError);
      }
    );
    return () => {
      if (currChannel.readMsgUsers) {
        const channelUpdates = { ...currChannel };
        if (!currChannel.readMsgUsers.includes(auth.userProfile.id)) {
          channelUpdates.readMsgUsers.push(auth.userProfile.id);
        }
        updateChannel(currChannel.id, channelUpdates);
      }
      unsubscribe();
    };
  }, [currChannel]);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [allMessages]);

  const checkPositionOfScroll = (e) => {
    if (
      isLoaded &&
      e.target.scrollTop <= 50 &&
      messageCount * -1 < allMessages.length
    ) {
      let mesCnt = messageCount;
      mesCnt += -1 * 10;
      setMessageCount(mesCnt);
      setMessages(allMessages.slice(mesCnt));
    }
  };
  const messageItemClick = (msgItem) => {
    // setLastImageURL(msgItem.image);
    // setIsLoadIMGPopup(true);
    // console.log(msgItem);
  };
  let dt = "";
  return (
    <>
      <div
        className="msgBox"
        style={{ height: window.innerHeight - 350 }}
        onScroll={checkPositionOfScroll}
      >
        {messages.map((message) => {
          let msgdt = moment(message.updatedOn).format("DD MMMM, YYYY");
          let returnArr = [];

          if (msgdt !== dt) {
            returnArr.push(
              <div className="messageContainer" key={msgdt}>
                <div className="dtMsg">
                  {/* <div className="title">
                  {moment(message.updatedOn).fromNow()}
                </div> */}
                  <div className="dtDisplay">{msgdt}</div>
                </div>
              </div>
            );
            dt = msgdt;
          }

          if (auth.userProfile.id === message.sender.userId) {
            returnArr.push(
              <div className="messageContainer" key={message.id}>
                <div
                  className="rightMsg"
                  style={message.type === "image" ? { width: "80%" } : {}}
                >
                  <MessageElement
                    message={message}
                    onMSGItemClick={messageItemClick}
                  />
                </div>
              </div>
            );
          } else {
            returnArr.push(
              <div className="messageContainer" key={message.id}>
                <div
                  className="leftMsg"
                  style={message.type === "image" ? { width: "80%" } : {}}
                >
                  <MessageElement
                    message={message}
                    onMSGItemClick={messageItemClick}
                  />
                </div>
              </div>
            );
          }
          return returnArr;
        })}
        <div ref={bottomRef} />
      </div>
      {/* {isLoadIMGPopup && <ModalImage small={largeImageURL} large={largeImageURL} alt="" onClose={()=> {setIsLoadIMGPopup(false)}}/>} */}
    </>
  );
};

function LiveChat(props) {
  const auth = useContext(AuthContext);
  const currChannel = auth.currentChannel;

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .MuiTooltip-tooltip`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      padding: 0,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #bbbbbb",
    },
  }));
  return (
    <Container>
      <PageTitle
        pageTitle="Chat Board"
        showBreadcrumb={[{ label: "Home", to: "/" }]}
        showLastSeen={true}
      />

      <div
        className={`sectionBox chatbox`}
        style={{ height: window.innerHeight - 180 }}
      >
        <Grid container className="chatboxcontianer">
          <Grid item xs={4} className="leftPanel" >
            <ChatLeftPannel />
          </Grid>
          <Grid item xs={8} className="rightPanel" >
            {currChannel ? (
              <>
                <div className="msgBoxHeader">
                  <div className="title">{currChannel.groupName}</div>
                  <div>{currChannel.description}</div>
                  <HtmlTooltip
                    arrow
                    placement="left"
                    title={
                      <React.Fragment>
                        <div className="tooltip-heading">
                          Members ({currChannel?.employee?.length})
                        </div>
                        <div className="tooltip-container">
                          {currChannel?.employee.map((emp) => (
                            <div className="pt-5" key={emp.id}>
                              <b>{`${emp.firstName} ${emp.lastName}`}</b>
                            </div>
                          ))}
                        </div>
                      </React.Fragment>
                    }
                  >
                    <div className="peopleIcon">
                      <PermContactCalendarOutlinedIcon fontSize="large"/>
                    </div>
                  </HtmlTooltip>
                </div>
                <DisplayMessagesBox currChannel={currChannel} />
                <SendTextMessageBox currChannel={currChannel} />
              </>
            ) : (
              <div className="noChannSelected"> Please select Channel</div>
            )}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
}

export default LiveChat;
