const Columns = [
  // { id: "checkbox", numeric: true, label: "", isSortable: false },
  {
    id: 'switch',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    isSortable: true,
  },
  {
    id: 'acuityId',
    numeric: false,
    disablePadding: true,
    label: 'Acuity ID',
    isSortable: true,
  },
  {
    id: 'userName',
    numeric: false,
    disablePadding: true,
    label: 'Employee Name',
    isSortable: true,
  },
  {
    id: 'phoneNumber',
    numeric: false,
    disablePadding: true,
    label: 'Contact',
    isSortable: true,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    isSortable: true,
  },
  {
    id: 'employeerole',
    numeric: false,
    disablePadding: false,
    label: 'Employee Role',
    isSortable: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
    rowclassName: '',
    className: 'pr-30',
  },
];

const ColumnsRes = [
  {
    id: 'employeerole',
    numeric: false,
    disablePadding: false,
    label: 'Employee Role',
    isSortable: true,
  },
  {
    id: 'phoneNumber',
    numeric: false,
    disablePadding: true,
    label: 'Contact',
    isSortable: true,
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    label: 'Email',
    isSortable: true,
  },
  {
    id: 'acuityId',
    numeric: false,
    disablePadding: true,
    label: 'Acuity ID',
    isSortable: true,
    isCollapsible: true,
  },
  {
    id: 'action',
    numeric: false,
    disablePadding: true,
    label: 'Action',
    isCollapsible: true,
  },
  {
    id: 'switch',
    numeric: false,
    disablePadding: false,
    label: 'Status',
    isSortable: true,
    isCollapsible: true,
  },
];

const Rows = [
  {
    acuityID: 'PC009485GS',
    employeename: 'William Thomas',
    contact: '+44 1632 960042',
    email: 'exampleemail7647@gmail.com',
  },
  {
    acuityID: 'PC009485GS',
    employeename: 'James lastname',
    contact: '+44 1632 960042',
    email: 'exampleemail7647@gmail.com',
  },
  {
    acuityID: 'PC009485GS',
    employeename: 'Anthony Thomas',
    contact: '+44 1632 960042',
    email: 'exampleemail7647@gmail.com',
  },
];

export { Columns, ColumnsRes, Rows };
