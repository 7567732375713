import React from "react";
import { Button } from "@material-ui/core";
import { deleteGroup } from "../../../services/apiservices/groups";
import { Typography } from "@material-ui/core";

const DeleteGroup = (props) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const onDeleteGroup = async () => {
    if(props?.group?.groupId){
      setIsLoading(true);
      await deleteGroup(
       props?.group?.groupId,
        (res) => {
          setIsLoading(false);
          props?.onSuccess && props?.onSuccess();
          props?.onClose && props?.onClose();
          props?.setRecordsDelete(props?.group);
          props?.setDialogOpen(true);
        },
        (resError) => {
          setIsLoading(false);
          console.log("error", resError);
        }
      );
    } else {
      setIsLoading(false);
    }
  };

  return (
  <>
    <div className="ph-20 pt-10 pb-10">
      <Typography variant="body" style={{ fontSize: '15px', fontWeight: "400", color: "#454545", lineHeight:"25px" }}> Are you sure you want to Delete " {props?.group?.title} " Group?</Typography>
      <div className="d-flex flex-justify-flexend pv-10" style={{marginTop:"50px"}}>
        <Button
          className="ph-35 mr-10"
          type="button"
          variant={"contained"}
          disabled={isLoading ? true: false}
          onClick={props?.onClose}
        >
          Cancel
        </Button>
        <Button
          className="ph-35 ml-10 w-35"
          type="button"
          color="primary"
          variant={"contained"}
          onClick={onDeleteGroup}
          disabled={isLoading ? true: false}
        >
          {isLoading ? "Loading..." : "Yes"}
        </Button>
      </div>
    </div>
  </>
  );
};
export default DeleteGroup;
