import React, { useContext, useEffect } from "react";
import { List, ListItem, IconButton, Badge } from "@material-ui/core";
import { NavLink, Link, useLocation } from "react-router-dom";
import { useState } from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import AuthContext from "../../hooks/authContext";
import { useMediaQuery } from "@mui/material";

const ChildMenuItem = (props) => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const { item, index, handleDrawerToggle } = props;
  const [showChild, setShowChild] = useState(false);
  const [isShowBadge, setIsShowBadge] = useState(false);
  const isMobile = useMediaQuery('(max-width:767px)');

  const hasinnerChild =
    typeof item?.childItems !== "undefined" && item?.childItems?.length > 0
      ? true
      : false;
  const showChildMenu = (e) => {
    e.preventDefault();
    setShowChild(!showChild);
  };

  const handleLinkClick = () => {
    if(isMobile){
      if (handleDrawerToggle) {
        handleDrawerToggle();
      }
    }
  };



  useEffect(() => {
    let showBadge = false;
    if (item.to === '/message-board/live-chat') {
      if (!auth.isReadPrivate || !auth.isReadPublic) {
        showBadge = true;
      }
    } else if (item.to === '/supplies/cleaning/review') {
      if (auth.pendingSupplyStatus.length > 0) {
        showBadge = true;
      }
    } else if (item.to === '/supplies/cleaning/manage') {
      if (auth.highPrioritySupply.length > 0) {
        showBadge = true;
      }
    } else if (item.to === '/issues/manage') {
      if (auth.issueCount.length > 0) {
        showBadge = true;
      }
    }

    setIsShowBadge(showBadge);
  }, [auth.pendingSupplyStatus, auth.highPrioritySupply, auth.issueCount, auth.isReadPrivate, auth.isReadPublic, item.to]);


  const renderMenuChilditem = (item) => {
    return (
      showChild === true && (
        <List key={"subitem" + item} className="submenuitems">
          {item?.childItems?.map((child, index) => {
            return <ChildMenuItem item={child} index={index} handleDrawerToggle={handleDrawerToggle}/>;
          })}
        </List>
      )
    );
  };

  return (
    <ListItem key={item.label + index}>
      {hasinnerChild === true ? (
        <>
          <div className="innerChildItemTitle">
            <Link to={item.to} className={item?.classes ? item?.classes : ""}>
              {item?.icon && item.icon} {item.label}
            </Link>
            <IconButton size="small" onClick={showChildMenu}>
              {showChild === true ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
            </IconButton>
          </div>
          {renderMenuChilditem(item)}
        </>
      ) : (
        <NavLink
          className={`${item?.classes ? item?.classes : ""}`}
          to={item.to}
          onClick={handleLinkClick}
        >
          {item?.icon && item.icon} {item.label} {`${item.isCountKey ? ' (' + auth.supplieStatusCount[item.isCountKey] + ')' : ''}`} {isShowBadge && <Badge color="secondary" variant="dot" className="ChildMenuBadgeIcon" />}
        </NavLink>
      )}
    </ListItem>
  );
};

export default ChildMenuItem;
