import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button, useMediaQuery } from "@material-ui/core";
import AuthContext from "../../hooks/authContext";
import styles from "./header.module.scss";
import { Link, Navigate } from "react-router-dom";
import moment from "moment";
// import cmsAdminLogo from "../../assets/images/cmsAdminLogo.png";
import csmWhiteText from "../../assets/images/csmWhiteText.png";
import csmWhiteNewText from "../../assets/images/csmWhiteNewText.png";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SidebarUserProfile from "../SidebarMenu/SidebarUserProfile";
const Header = (props) => {
  const auth = useContext(AuthContext);
  const isMobile = useMediaQuery('(max-width:767px)');  
  let history = useNavigate();
  const [sRedirect, setRedirect] = useState(false);

  const logoutuser = (e) => {
    e.preventDefault();
    auth.logoutuser();
    history('/login')
  };

  useEffect(() => {
    if (auth.status !== true) {
      setRedirect(true);
    }
  }, [auth.status]);

  return auth?.status === true ? (
    <div className={styles.headerWrap}>
      {isMobile ? (
        <>
          {sRedirect === true && <Navigate to="/login" />}
          <div className={`d-flex pv-15 flex-center`}>
            <Link to="/" className={styles.logowrap}>
              <Typography component="h6" variant="h6" className={"pr-2"}>
                <img src={csmWhiteNewText} alt="PROTeam Admin Logo" className="cms-logo" />
                {/* {"Procleanings"} <span className={`badge`}>Admin</span> */}
              </Typography>
            </Link>
          </div>
        </>
      ) : (
        <>
          {sRedirect === true && <Navigate to="/login" />}
          <div className="d-flex flex-center">
            <Link to="/" className={styles.logowrap}>
              <Typography component="h6" variant="h6" className={"pr-2"}>
                <img src={csmWhiteNewText} alt="PROTeam Admin Logo" className="cms-logo" />
                {/* {"Procleanings"} <span className={`badge`}>Admin</span> */}
              </Typography>
            </Link>
          </div>
          <div className={`${styles.headerRight} headerRight`}>
            <ul className={`menulist usersWrap`}>
              <li className="userInfo">
                {/* <SidebarUserProfile auth={auth} /> */}
                <Link to="/profile">
                  <Typography className="name">
                    {`${auth.userProfile?.firstName} ${auth.userProfile?.lastName}`}
                  </Typography>
                </Link>
                <Typography variant="caption">
                  Last Login. <span className="captionTitle">{moment(auth.userProfile?.lastLogin).format("MMMM D, YYYY h:mm:ss")}</span>
                </Typography>
              </li>
              {/* <li>
                <Button variant="text" onClick={logoutuser} startIcon={<ExitToAppIcon />}>Logout</Button>
              </li> */}
            </ul>
          </div>
        </>
      )}
    </div>
  ) : (
    <></>
  );
};

export default Header;