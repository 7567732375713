import React, { useContext, useEffect, useState } from "react";
import {Container, Tooltip } from "@material-ui/core";
import PageTitle from "../../../components/pageTitle/PageTitle";
import ThemeTable from "../../../components/Table/ThemeTable";
import { getClientsListbasedonIds } from "../../../services/apiservices/client";
import { getEmployeesListBasedOnId } from "../../../services/apiservices/employee";
import { getSupplyOrderList, updateArchive, updateSuppliesStatus, updateSupplyItemsStatus } from "../../../services/apiservices/supplies";
import CommonContext from "../../../hooks/commonContext";
import { FormatedDates, groupByKey } from "../../../services/helpers";
import TableToolbar from "../../../components/Table/TableToolbar";
import SingleSelect from "../../../components/SelectField/SingleSelect";
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useNavigate } from "react-router";
import { useMediaQuery } from "@mui/material";
import SupplyCommonCard from "../../../components/ResponsiveCardComponent/SupplyComponent/SupplyListPageResponsive";
import { ConfirmationsUnArchiveDialog, CustomTooltip, SuccessDialog, SuppplyDataDialog } from "../../../components/DialogComponent/SupplyDataDialog";


const ArchiveSupplies = (props) => {
    const commonContext = useContext(CommonContext);
    const [allSupplies, setAllSupplies] = React.useState([]);
    const [filteredList, setFilteredList] = React.useState([]);
    const [allClient, setAllClients] = React.useState([]);
    const [allPriority, setAllPriority] = React.useState([]);
    const [allOrderStatus, setAllOrderStatus] = React.useState([]);
    const [selectedPriority, setSelectedPriority] = useState("all");
    const [filter, setFilterValues] = React.useState({
        priority: "all",
        client: "any",
        status: "any",
    });
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogConfirmOpen, setDialogConfirmOpen] = useState(false);
    const isMobile = useMediaQuery('(max-width:767px)');
    const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');
    const [expanded, setExpanded] = useState(null);
    const [selectedItem, setSelectedItem] = React.useState(null);
    const history = useNavigate();
    const [openSupplyDialog, setOpenSupplyDialog] = useState(false);
    const [openData, setOpenData] = useState(null);
    const getColumns = [
        {
            id: "orderId",
            numeric: false,
            disablePadding: true,
            label: "Order Id",
            isSortable: true,
        },
        {
            id: "dateOfRequest",
            numeric: false,
            disablePadding: false,
            label: "Order Date",
            isSortable: true,
        },
        {
            id: "employeeName",
            numeric: false,
            disablePadding: true,
            label: "Employee Name",
            isSortable: true,
        },
        {
            id: "clientName",
            numeric: false,
            disablePadding: false,
            label: "Client Name",
            isSortable: true,
        },
        {
            id: "supplies",
            numeric: false,
            disablePadding: true,
            label: "Supplies",
            isSortable: true,
        },
        {
            id: "priority",
            numeric: false,
            disablePadding: false,
            label: "Priority",
            isSortable: true,
        },
        {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Order Status",
            isSortable: true,
        },
        {
            id: "deliverStatus",
            numeric: false,
            disablePadding: false,
            label: "Deliver Status",
            isSortable: true,
        },
        {
            id: "action",
            numeric: false,
            disablePadding: false,
            label: "Action",
            isSortable: true,
        }
    ];

    const ColumnsRes = [ 
        {
            id: "clientName",
            numeric: false,
            disablePadding: false,
            label: "Client",
            isSortable: true,
        },
        {
            id: "priority",
            numeric: false,
            disablePadding: false,
            label: "Priority",
            isSortable: true,
        },
        {
            id: "status",
            numeric: false,
            disablePadding: false,
            label: "Order Status",
            isSortable: true,
        },
        {
            id: "dateOfRequest",
            numeric: false,
            disablePadding: false,
            label: "Order Date",
            isSortable: true,
            isCollapsible: true,
        },
        {
            id: "employeeName",
            numeric: false,
            disablePadding: true,
            label: "Employee Name",
            isSortable: true,
            isCollapsible: true,
        },
        {
            id: "supplies",
            numeric: false,
            disablePadding: true,
            label: "Supplies",
            isSortable: true,
            isCollapsible: true,
        },
        {
            id: "deliverStatus",
            numeric: false,
            disablePadding: false,
            label: "Deliver Status",
            isSortable: true,
            isCollapsible: true,
        },
        {
            id: "unArchive",
            numeric: false,
            disablePadding: false,
            label: "UnArchive",
            isSortable: true,
            isCollapsible: true,
        }
    ]

    const checkFilteredList = () => {
        if (allSupplies) {
            let filteredSuppliesList = allSupplies?.filter((row) => {
                let searchFilter = true,
                    filterorderStatus = true,
                    filterpriority = true,
                    filterclientName = true;
                if (filter?.priority !== "" && filter?.priority !== "all") {
                    filterpriority =
                        String(row.priority).toLowerCase() ===
                        String(filter.priority).toLowerCase();
                }
                if (filter?.status !== "" && filter?.status !== "any") {
                    filterorderStatus =
                        String(row.status).toLowerCase() ===
                        String(filter.status).toLowerCase();
                }
                if (filter?.client !== "" && filter?.client !== "any") {
                    filterclientName =
                        String(row.clientName).toLowerCase() ===
                        String(filter.client).toLowerCase();
                }
                return searchFilter && filterpriority && filterorderStatus && filterclientName;
            });
            setFilteredList(filteredSuppliesList);
        }
    };

    useEffect(() => {
        checkFilteredList();
    }, [allSupplies, filter]);


    const manageDataList = (newRows) => {
        // set data for filter fields
        let allpriority = groupByKey(newRows, "priority");
        let allpriorityOptions = Object.keys(allpriority)?.map((acitem) => {
            return { label: acitem, value: acitem };
        });
        let finalSetAllPriority = allpriorityOptions ? allpriorityOptions : [];
        setAllPriority([...finalSetAllPriority]);

        let allclients = groupByKey(newRows, "clientName");
        let allclientOptions = Object.keys(allclients)?.map((acitem) => {
            return { label: acitem, value: acitem };
        });
        let finalSetAllClient = allclientOptions ? allclientOptions : [];
        setAllClients([...finalSetAllClient]);

        let allOrderStatus = groupByKey(newRows, "status");
        let allOrderStatusOption = Object.keys(allOrderStatus)?.map((aacitem) => {
            return { label: aacitem, value: aacitem };
        });
        let finalSetAllOrderStatus = allOrderStatusOption ? allOrderStatusOption : [];
        setAllOrderStatus([...finalSetAllOrderStatus]);
    };


    const fetchSuppliesList = async () => {
        await getSupplyOrderList(
            {},
            async (res) => {
                if (res) {
                    let records = res.filter(order => order.status !== "Pending");
                    let filterOrders = records.filter(item => item.archive === true);

                    let clientIds = filterOrders.map((ordItem) => ordItem.clientId);
                    clientIds = [...new Set(clientIds)]

                    let empIds = filterOrders.map((orderItem) => orderItem.createdBy);
                    empIds = [...new Set(empIds)]

                    let clintsData = [];
                    if (clientIds.length > 0) {
                        await getClientsListbasedonIds(clientIds, (result) => {
                            const clients = result.map((item) => {
                                return { value: item?.id, label: item?.clientName };
                            });
                            clintsData = clients;
                        });
                    }

                    let employeeData = [];
                    if (empIds.length > 0) {
                        await getEmployeesListBasedOnId(empIds, (result) => {
                            const employees = result.map((item) => {
                                return { value: item?.userId, label: `${item?.firstName} ${item?.lastName}` };
                            });
                            employeeData = employees;
                        });
                    }
                    let newRows = filterOrders.map((ritem) => {
                        let totalQuantity = 0;

                        const client = clintsData.find(client => client?.value === ritem?.clientId);
                        const clientName = client ? client.label : 'Unknown Client';

                        const employee = employeeData.find(emp => emp?.value === ritem?.createdBy);
                        const employeeName = employee ? employee.label : 'Unknown employee';

                        if (ritem.supplyItems) {
                            ritem.supplyItems.forEach((item) => {
                                totalQuantity += item.qty || 0;
                            });
                        }
                        return {
                            ...ritem,
                            orderId: String(ritem?.orderNumber).padStart(6, '0'),
                            supplies: `(${totalQuantity})`,
                            employeeName: employeeName,
                            clientName: clientName,
                        };
                    });

                    let sortedData = sortingFunction(newRows);
                    await manageDataList(sortedData);
                    await setAllSupplies(sortedData);
                    commonContext?.setLoader(false);
                } else {
                    commonContext?.setLoader(false);
                }
            },
            (resError) => {
                console.error("reserror", resError);
                commonContext?.setLoader(false);
            }
        );
        commonContext?.setLoader(true);
    };


    useEffect(() => {
        fetchSuppliesList();
    }, []);

    const sortingFunction = (data) => {
        const result = data.sort((a, b) => {
            if (a.orderId < b.orderId) return -1;
            if (a.orderId > b.orderId) return 1;

            if (a.employeeName < b.employeeName) return -1;
            if (a.employeeName > b.employeeName) return 1;

            return 0;
        });

        return result;
    };


    const priorityOptions = [
        { label: "All", value: "all" },
        { label: "High", value: "1" },
        { label: "Medium", value: "2" },
        { label: "Low", value: "3" }
    ];

    const handlePriorityChange = (e) => {
        const value = e.target.value;
        setSelectedPriority(value);
        setFilterValues(prev => ({ ...prev, priority: value }));
    };


    const setFilter = (name, value) => {
        setFilterValues({ ...filter, [name]: value });
    };

    const tableRightComponent = () => {
        return (
            <div className="d-flex flex-center w320 editEmp issueAlign pr-2">
                <div className="d-flex flex-center ml-12 w-100 mv-7">
                    <SingleSelect
                        label="Priority"
                        value={selectedPriority}
                        className={`field w-60`}
                        name={"Priority"}
                        onChange={handlePriorityChange}
                        options={priorityOptions}
                    />
                </div>
                <div className="d-flex flex-center ml-12 w-100 mv-7">
                    <SingleSelect
                        label="Client"
                        value={filter?.client ? filter?.client : "any"}
                        className={`field w90 `}
                        name={"client"}
                        onChange={(e) => {
                            setFilter("client", e?.target?.value);
                        }}
                        options={
                            allClient
                                ? [{ label: "Any", value: "any" }, ...allClient]
                                : [{ label: "Any", value: "any" }]
                        }
                    />
                </div>
                <div className="d-flex flex-center ml-12 w-100 mv-7">
                    <SingleSelect
                        label="Order Staus"
                        value={filter?.status ? filter?.status : "any"}
                        className={`field w90 `}
                        name={"Order Staus"}
                        onChange={(e) => {
                            setFilter("status", e?.target?.value);
                        }}
                        options={
                            allOrderStatus
                                ? [{ label: "Any", value: "any" }, ...allOrderStatus]
                                : [{ label: "Any", value: "any" }]
                        }
                    />
                </div>
            </div>
        )
    }

    const redirectToDetails = (id) => {
        if (typeof id !== "undefined" && id !== "")
            history("/supplies/cleaning/archives/" + id);
    };
    const handleProceed = async (rcellItem) => {
        const formData = {
            archive: false
        }
        const data = {
            status: "Approved"
        }
        const id = rcellItem?.id;
        try {
            if (rcellItem?.status === "Approved") {
                await updateArchive(id, formData);
            } else {
                const promises = [
                    updateSuppliesStatus(id, data),
                    updateSupplyItemsStatus(id, "Ordered"),
                    updateArchive(id, formData),
                ];
                await Promise.all(promises);
            }
            setDialogConfirmOpen(false);
            setDialogOpen(true);
            setSelectedItem(null);
        } catch (error) {
            console.error("Error during unarchiving process:", error);
        }
    }

    const handelUnArchive = async (rcellItem) => {
        setDialogConfirmOpen(true);
        setSelectedItem(rcellItem);
    };

    const handleDialogConfirmClose = () => {
        setDialogConfirmOpen(false);
        setSelectedItem(null);
    };


    const handleCloseDialog = async () => {
        setDialogOpen(false);
        setDialogConfirmOpen(false);
        await fetchSuppliesList();
    };

    const getDeliveryStatus = (supplyItems) => {
        if (!supplyItems || !Array.isArray(supplyItems)) {
            return 'Invalid Supplies';
        }

        const statuses = supplyItems.map(item => item.status);

        const hasDelivered = statuses.includes('Delivered');
        const hasShipped = statuses.includes('Shipped');
        const hasOrdered = statuses.includes('Ordered');
        const hasBackOrdered = statuses.includes('Back Ordered');
        const hasCancelItem = statuses.includes('Cancel Item');

        if (statuses.every(status => status === 'Delivered') || (hasDelivered && hasCancelItem && !hasOrdered && !hasBackOrdered && !hasShipped)) {
            return 'Full Delivery';
        }
        if (statuses.every(status => status === 'Cancel Item')) {
            return 'cancelled';
        }
        if (hasDelivered && (hasShipped || hasOrdered || hasBackOrdered || hasCancelItem)) {
            return 'Partial Delivery';
        }
        if (statuses.every(status => status === 'Shipped') || (hasShipped && hasCancelItem && !hasDelivered && !hasOrdered && !hasBackOrdered)) {
            return 'Full Shipment';
        }
        if (hasShipped && (hasOrdered || hasBackOrdered) && !hasDelivered) {
            return 'Partial Shipment';
        }
        if (hasOrdered && hasCancelItem) {
            return 'In Process';
        }
        if (statuses.every(status => status === 'Ordered')) {
            return 'In Process';
        }
        if (statuses.every(status => status === 'Back Ordered')) {
            return 'Back Ordered';
        }
        if (hasOrdered && hasBackOrdered) {
            return 'In Process';
        }
        return 'In Process';
    };

    const getPriorityLabel = (priority) => {
        switch (priority) {
            case "1":
                return "High";
            case "2":
                return "Medium";
            case "3":
                return "Low";
            default:
                return "Unknown";
        }
    };

    const renderRowCell = (rcellItem, hcellItem, index, rowindex) => {
        switch (hcellItem.id) {
            case "supplies":
                return (
                    <>
                        <CustomTooltip
                            listData={rcellItem?.supplyItems}
                            title={`${rcellItem?.supplies}`}
                        />
                    </>
                )
                break;
            case "dateOfRequest":
                return FormatedDates(rcellItem?.dateOfRequest);
            case "deliverStatus":
                return <>{getDeliveryStatus(rcellItem?.supplyItems)}</>;
                break;
            case "priority":
                return <>{getPriorityLabel(rcellItem?.priority)}</>;
                break;
            case "action":
                return (
                    <>
                        <div>
                            <Tooltip title="UnArchive">
                                <UnarchiveIcon className="color-primary" onClick={() => handelUnArchive(rcellItem)} />
                            </Tooltip>
                            <VisibilityIcon className="ml-20 color-primary" onClick={() => redirectToDetails(rcellItem?.id)} />
                        </div>
                    </>
                );
                break;
            default:
                return rcellItem[hcellItem?.id];
        }
    };

    const handleExpandClick = (id) => {
        setExpanded(prevExpanded => (prevExpanded === id ? null : id));
    };

    const handleDialogSupplyOpen = async (data) => {
        setOpenSupplyDialog(true);
        setOpenData(data);
    };

    const handleDialogSupplyClose = async () => {
        setOpenSupplyDialog(false);
        setOpenData(null);
    };

    return (
        <>
            <Container>
                <PageTitle
                    pageTitle="Supplies"
                    showBreadcrumb={[
                        { label: "Home", to: "/" },
                    ]}
                    BreadCrumbCurrentPageTitle={"Archive Supplies Request"}
                />

                <div className={`sectionBox2`}>
                    <TableToolbar
                        title=""
                        rightComponent={tableRightComponent()}
                    />
                    {(!isMobile && !isLandscape) &&
                        <ThemeTable
                            rows={filteredList}
                            headCells={getColumns}
                            hidePagination={false}
                            renderRowCell={renderRowCell}
                        />
                    }
                    {(isMobile || isLandscape) && (
                        <>
                            {filteredList.map((record, index) => {
                                const isOpen = expanded === record?.id;
                                return (
                                    <SupplyCommonCard
                                        index={index}
                                        record={record}
                                        isOpen={isOpen}
                                        handleExpandClick={handleExpandClick}
                                        getPriorityLabel={getPriorityLabel}
                                        redirectToDetails={redirectToDetails}
                                        getDeliveryStatus={getDeliveryStatus}
                                        handelUnArchive={handelUnArchive}
                                        handelLinkUnArchive={handelUnArchive}
                                        handleDialogSupplyOpen={handleDialogSupplyOpen}
                                        columns={ColumnsRes}
                                        supplyUnarchive={true}
                                    />
                                );
                            })}
                        </>
                    )}
                </div>
            </Container>
            {dialogConfirmOpen && (
                <ConfirmationsUnArchiveDialog
                    open={dialogConfirmOpen}
                    onConfirm={() => handleProceed(selectedItem)}
                    onClose={handleDialogConfirmClose}
                />
            )}
            <SuccessDialog open={dialogOpen} onClose={handleCloseDialog} Archive={true}/>
            <SuppplyDataDialog open={openSupplyDialog} onClose={handleDialogSupplyClose} listData={openData} />
        </>
    );
}

export default ArchiveSupplies;