import { firebase } from '../firebase-config';
import { COLLECTIONS } from '../config';
import {
  deleteItem,
  updateItem,
  addItem,
  // getOneItem,
  // getList,
} from './firebaseBootstrap';

const collection = COLLECTIONS.SUPPLIES_CLEANINGS;
const collection_order = COLLECTIONS.SUPPLIES_ORDER;
const collection_category = COLLECTIONS.SUPPLIES_CATEGORY;
const collection_notes = COLLECTIONS.SUPPLIES_ORDER_NOTES;

export async function getSuppliesListCount(formData, onSuccess, onError) {
  let colRef = firebase.firestore().collection(collection_order);
  colRef
    .where('status', 'in', [...formData.status])
    .get()
    .then((querySnapshot) => {
      let results = [];
      let statusCount = {};
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        statusCount[data?.status] = statusCount[data?.status] ? statusCount[data?.status] + 1 : 1;
      });

      formData.status.map((statuss) => {
        if (!statusCount[statuss]) {
          statusCount[statuss] = 0;
        }
      });
      results.push(statusCount);
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
}

export async function getSupplyOrderList(formData, onSuccess, onError) {
  let colRef = firebase.firestore().collection(collection_order);
  colRef
    // .where('status', 'in', formData.status)
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data, ...data.content });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
}

export async function getSupplyOrderListBasedOnId(id, onSuccess, onError) {
  let docRef = firebase.firestore().collection(collection_order).doc(id);
  
  docRef
    .get()
    .then((doc) => {
      if (doc.exists) {
        let data = doc.data();
        let result = { id: doc.id, ...data, ...data.content };
        onSuccess && onSuccess(result);
      } else {
        // Document does not exist
        onSuccess && onSuccess(null);
      }
    })
    .catch((error) => {
      console.log('Error getting document: ', error);
      onError && onError(error);
    });
}

export async function getSuppliesList(formData, onSuccess, onError) {
  let colRef = firebase.firestore().collection(collection);
  if (typeof formData.status !== 'undefined' && formData.status !== '') {
    colRef
      .where('status', 'in', [...formData.status])
      .get()
      .then((querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          results.push({ id: doc.id, ...data });
        });
        onSuccess && onSuccess(results);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
        onError && onError(error);
      });
  } else {
    colRef
      .get()
      .then((querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          results.push({ id: doc.id, ...data, ...data.content });
        });
        onSuccess && onSuccess(results);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
        onError && onError(error);
      });
  }
}

export async function getSupplies(id, onSuccess, onError) {
  const supplyItemcolref = firebase.firestore().collection(collection);
  const supplycolref = firebase.firestore().collection(collection).doc(id);

  supplycolref
    .get()
    .then((doc) => {
      if (!doc.exists) {
        onError &&
          onError({
            status: false,
            error: 'Document not found',
          });
      }
      let data = doc.data();
      let docContent = { id: doc.id, ...data };
      // console.log('mainjjf', docContent);
      return { status: true, data: docContent };
    })
    .then((res) => {
      if (res?.status === true) {
        supplyItemcolref
          .doc(id)
          .collection('SupplieItems')
          .get()
          .then((response) => {
            let clientName = res?.data?.client?.name;
            const suppliesItems = [];
            response.forEach(async (document) => {
              const docData = document.data();
              const sitem = {
                id: document.id,
                clientName: clientName,
                ...docData,
              };
              suppliesItems.push(sitem);
            });
            onSuccess({
              status: true,
              data: res?.data,
              SupplieItems: suppliesItems,
            });
          });
      } else {
        onSuccess({
          status: true,
          data: res?.data,
        });
      }
    })
    .catch((error) => {
      onError && onError({ status: false, error: error });
    });
}
export async function getSuppliesByClientItems(formData, onSuccess, onError) {
  if (formData?.Clients && formData?.Clients?.length > 0) {
    const supplyItemcolref = firebase.firestore().collection(collection);
    supplyItemcolref
      .where('client.name', 'in', [...formData?.Clients])
      .orderBy('dateOfRequest', 'desc')
      .get()
      .then((querySnapshot) => {
        let results = [];
        querySnapshot.forEach((doc) => {
          let data = doc.data();
          results.push({ id: doc.id, ...data });
        });
        onSuccess && onSuccess(results);
      })
      .catch((error) => {
        console.log('Error getting documents: ', error);
        onError && onError(error);
      });
  }
}

export async function addSupplies(formData, onSuccess, onError) {
  addItem('users', formData, onSuccess, onError);
}

export async function updateSupplies(id, formData, onSuccess, onError) {
  updateItem(collection, id, formData, onSuccess, onError);
}

export async function updateSuppliesItemsData(
  id,
  SupplieItemsId,
  formData,
  saveStatus,
  onSuccess,
  onError
) {
  const colref = firebase.firestore().collection(collection);
  console.log('on change status', id, SupplieItemsId, formData);

  if (saveStatus === 'Approved') {
    colref
      .doc(id)
      .update({ status: saveStatus, orderStatus: 'Pending' })
      .then(() => {
        const documentref = colref
          .doc(id)
          .collection('SupplieItems')
          .doc(SupplieItemsId);
        documentref
          .get()
          .then(async (doc) => {
            if (!doc.exists) {
              return { status: false, error: 'Document  not found' };
            }
            return { status: true, id: doc.id, data: doc.data() };
          })
          .then((oldDoc) => {
            if (oldDoc.status === true) {
              return documentref.update({
                ...oldDoc.data,
                ...formData,
              });
            } else {
              return oldDoc;
            }
          })
          .then((finaldata) => {
            onSuccess(finaldata);
          })
          .catch((reserror) => {
            onError(reserror);
          });
        console.log('updated');
      });
  } else {
    const documentref = colref
      .doc(id)
      .collection('SupplieItems')
      .doc(SupplieItemsId);
    documentref
      .get()
      .then(async (doc) => {
        if (!doc.exists) {
          return { status: false, error: 'Document  not found' };
        }
        return { status: true, id: doc.id, data: doc.data() };
      })
      .then((oldDoc) => {
        if (oldDoc.status === true) {
          return documentref.update({
            ...oldDoc.data,
            ...formData,
          });
        } else {
          return oldDoc;
        }
      })
      .then((finaldata) => {
        onSuccess(finaldata);
      })
      .catch((reserror) => {
        onError(reserror);
      });
  }
}

export async function saveRejectionReson(
  id,
  SupplieItemsId,
  formData,
  onSuccess,
  onError
) {
  try {
    const colref = firebase.firestore().collection(collection);
    colref
      .doc(id)
      .collection('SupplieItems')
      .doc(SupplieItemsId)
      .update({
        ...formData,
      });
    onSuccess && onSuccess({ status: true });
  } catch (reserror) {
    onError && onError(reserror);
  }
}

export async function deleteSupplies(id, onSuccess, onError) {
  if (!id || id === '') {
    deleteItem(collection, id, onSuccess, onError);
  } else {
    onError({ status: false, message: 'Invalid input' });
  }
}

export async function updateSuppliesStatus(id, formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  if (!id || id.trim() === "") {
    onError && onError({ status: false, message: "Id is Missing" });
    return;
  }

  try {
    const colRef = firebase.firestore().collection(collection_order);
    const updateData = {
      ...formData,
      updateBy: currentUserId,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      ...(formData.status === "Rejected" && { archive: true }),
    };

    await colRef.doc(id).set(updateData, { merge: true });
    onSuccess && onSuccess({ status: true, data: formData });

  } catch (error) {
    console.error("Error updating document:", error);
    onError && onError({ status: false, message: error.message });
  }
}

export async function getCategories(id, onSuccess, onError) {
  let colRef = firebase.firestore().collection(collection_category);
  colRef
    .get(id)
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data});
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
}

export async function addNotes(formData, onSuccess, onError) {
  const colRef = firebase.firestore().collection(collection_notes);
  
  try {
    const docRef = await colRef.add({
      ...formData,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    });

    const docData = { ...formData, id: docRef.id };

    if (onSuccess && typeof onSuccess === 'function') {
      onSuccess({ status: true, data: docData });
    }
  } catch (error) {

    if (onError && typeof onError === 'function') {
      onError({ status: false, error: error.message || error });
    }
  }
}

export async function fetchNotes(id, onSuccess, onError) {
  if (!id) {
    if (onError) {
      onError(new Error('No ID provided'));
    }
    return;
  }

  let colRef = firebase.firestore().collection(collection_notes);
  colRef
    .where('orderId', '==', id)
    .get()
    .then((querySnapshot) => {
      const notes = [];
      querySnapshot.forEach((doc) => {
        notes.push(doc.data());
      });
      if (notes.length === 0) {
        notes.push({ message: 'No Available Notes' });
      }
      if (onSuccess) {
        onSuccess(notes);
      }
    })
    .catch((error) => {
      if (onError) {
        onError(error);
      }
    });
}


export async function updateSupplyItemsStatus(id, newStatus, itemId, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  const colRef = firebase.firestore().collection(collection_order);
  try {
    const docRef = colRef.doc(id);
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data();
      let updatedSupplyItems;

      if (Array.isArray(itemId)) {
        updatedSupplyItems = data.supplyItems.map(item => {
          if (itemId.includes(item.id)) {
            return {
              ...item,
              status: newStatus
            };
          }
          return item;
        });
      } else if (itemId) {
        updatedSupplyItems = data.supplyItems.map(item => {
          if (item.id === itemId) {
            return {
              ...item,
              status: newStatus
            };
          }
          return item;
        });
      } else {
        updatedSupplyItems = data.supplyItems.map(item => ({
          ...item,
          status: newStatus
        }));
      }

      const now = firebase.firestore.FieldValue.serverTimestamp();

      await docRef.update({
        supplyItems: updatedSupplyItems,
        updateBy: currentUserId,
        updatedAt: now
      });

      onSuccess && onSuccess({ id: doc.id, ...data, supplyItems: updatedSupplyItems, updatedAt: now });
    } else {
      onError && onError("No such document!");
    }
  } catch (error) {
    onError && onError(error);
  }
}



export async function updateSupplyItemDate(id, itemId, date, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  const colRef = firebase.firestore().collection(collection_order);
  try {
    const docRef = colRef.doc(id);
    const doc = await docRef.get();

    if (doc.exists) {
      const data = doc.data();
      let updatedSupplyItems = data.supplyItems.map(item => {
        if (item.id === itemId) {
          const timestamp = firebase.firestore.Timestamp.fromDate(new Date(date));
          return { ...item, deliveryDate: timestamp };
        }
        return item;
      });

      const now = firebase.firestore.FieldValue.serverTimestamp();

      await docRef.update({
        supplyItems: updatedSupplyItems,
        updateBy: currentUserId,
        updatedAt: now
      });

      onSuccess && onSuccess({ id: doc.id, ...data, supplyItems: updatedSupplyItems, updatedAt: now });
    } else {
      onError && onError("No such document!");
    }
  } catch (error) {
    onError && onError(error);
  }
}

export async function supplyRejectReasons(id, result, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  if (!id || id.trim() === "") {
    onError && onError({ status: false, message: "Id is Missing" });
    return;
  }

  try {
    const colRef = firebase.firestore().collection(collection_order);
    const docRef = colRef.doc(id);

    const newReason = {
      reason: result.reason,
      message: result.message,
    };

    await docRef.update({
      rejectReason: firebase.firestore.FieldValue.arrayUnion(newReason),
      updateBy: currentUserId,
      updatedAt: firebase.firestore.Timestamp.now(),
    });

    onSuccess && onSuccess({ status: true });

  } catch (error) {
    console.error("Error updating document:", error);
    onError && onError({ status: false, message: error.message });
  }
}

export async function updateArchive(id, formData, onSuccess, onError) {
  let currentUserId = firebase.auth().currentUser.uid;
  if (!id || id.trim() === "") {
    onError && onError({ status: false, message: "Id is Missing" });
    return;
  }

  try {
    const colRef = firebase.firestore().collection(collection_order);
    const updateData = {
      ...formData,
      updateBy: currentUserId,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    await colRef.doc(id).set(updateData, { merge: true });
    onSuccess && onSuccess({ status: true, data: formData });

  } catch (error) {
    console.error("Error updating document:", error);
    onError && onError({ status: false, message: error.message });
  }
}