const Columns = [
  // { id: "checkbox", numeric: true, label: "", isSortable: false },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isSortable: true,
  },
  {
    id: "isuueId",
    numeric: false,
    disablePadding: true,
    label: "Issue #",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    isSortable: true,
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
    isSortable: true,
  },
  {
    id: "lastupdated",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
    isSortable: true,
  },
  {
    id: "lastupdatedtime",
    numeric: false,
    disablePadding: false,
    label: "Time",
    isSortable: false,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },
  
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
    rowclassName: "",
    className: "pr-30",
  },
];

const ColumnsRes = [
  // { id: "checkbox", numeric: true, label: "", isSortable: false },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client",
    isSortable: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Name",
    isSortable: true,
    isCollapsible: true,
  },
  {
    id: "lastupdated",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
    isSortable: true,
    isCollapsible: true,
  },
  {
    id: "lastupdatedtime",
    numeric: false,
    disablePadding: false,
    label: "Time",
    isSortable: false,
    isCollapsible: true,
  }
];

const DetailsColumns = [
  // { id: "checkbox", numeric: true, label: "", isSortable: false },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
    isSortable: false,
  },
  {
    id: "issuueId",
    numeric: false,
    disablePadding: true,
    label: "Issue #",
    isSortable: false,
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
    isSortable: false,
  },
  {
    id: "openedOn",
    numeric: false,
    disablePadding: false,
    label: "Open Date",
    isSortable: false,
  },
  {
    id: "lastupdated",
    numeric: false,
    disablePadding: false,
    label: "Last Updated",
    isSortable: false,
  },
  {
    id: "lastupdatedtime",
    numeric: false,
    disablePadding: false,
    label: "Time",
    isSortable: false,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: false,
  },
  
  // {
  //   id: "action",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Action",
  //   rowclassName: "text-right",
  //   className: "text-right pr-30",
  // },
];

const Rows = [
  {
    messageStatus: "Escalated-I",
    id: "#IS00293",
    employeeName: "Smith Thomas",
    clientName: "MTA",
    lastupdated:"June 15, 2021",
    lastupdatedtime:'10:34:56 am',
    priority:'High'
  },
  {
    messageStatus: "Escalated-E",
    id: "#IS00294",
    employeeName: "Johnsan",
    clientName: "MTA",
    lastupdated:"June 15, 2021",
    lastupdatedtime:'10:34:56 am',
    priority:'Low'
  },
  {
    messageStatus: "Responded",
    id: "#IS00295",
    employeeName: "Williams",
    clientName: "AIRBNB",
    lastupdated:"June 15, 2021",
    lastupdatedtime:'10:34:56 am',
    priority:'High'
  },
  {
    messageStatus: "Responded",
    id: "#IS00297",
    employeeName: "David",
    clientName: "ABC",
    lastupdated:"June 15, 2021",
    lastupdatedtime:'10:34:56 am',
    priority:'Low'
  },
  {
    messageStatus: "Resolved",
    id: "#IS00298",
    employeeName: "Richard",
    clientName: "MTA",
    lastupdated:"June 15, 2021",
    lastupdatedtime:'10:34:56 am',
    priority:'Low'
  },
  {
    messageStatus: "Closed",
    id: "#IS00300",
    employeeName: "Smith Thomas",
    clientName: "XYZ",
    lastupdated:"June 15, 2021",
    lastupdatedtime:'10:34:56 am',
    priority:'Low'
  },
];

export { Columns, ColumnsRes, DetailsColumns, Rows };
