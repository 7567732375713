/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from "../../../components/AttachmentsView/Attachments.module.scss";
//import FileViewer from "react-file-viewer";
import ModalImage from "react-modal-image";
import { useMediaQuery } from "@mui/material";

const ViewAttachments = (props) => {
  const [attachedFiles, setAttachedFiles] = useState([]);
  const isMobile = useMediaQuery('(max-width:767px)');
  const isLandscape = useMediaQuery('(min-width: 771px) and (max-width: 940px)');

  
  useEffect(() => {
    setAttachedFiles(props.attachments);
  }, [props.attachments]);

  const fileItem = (item, index) => {
    return (
      item !== "" && (
        <li key={"file" + index} className={styles.attachmentItem}>
          <PreviewItem item={item} />
        </li>
      )
    );
  };
  return (
    <ul className={`attachments d-flex ${isMobile || isLandscape ? 'flex-wrap' : ''}`}>
      {attachedFiles &&
        attachedFiles?.length > 0 &&
        attachedFiles?.map(
          (fileitem, index) => fileItem(fileitem, index)
          // return <li className={styles.attachmentItem}>{fileitem.name}{fileitem.type}</li>;}
        )}
    </ul>
  );
};
export default ViewAttachments;

const PreviewItem = (props) => {

  const { item } = props;

  return (
    <>
    <div className={styles.imageDisplay}>
      <ModalImage
        small={item.url}
        large={item.url}
        hideDownload
        showRotate
      />
    </div>
    </>
  );
};
