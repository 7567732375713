import { firebase } from '../firebase-config';
import { BaseUrl, COLLECTIONS } from '../config';
import axiosInstance from '../axios';
import { getDocs, doc, updateDoc } from "firebase/firestore"; 

const collection = COLLECTIONS.CLIENT;
/**
 * Get Document list from client collection
 * @param {*} formData : can be use for filter in future
 * @param {*} onSuccess
 * @param {*} onError
 */
export const getClientsList = async (formData, onSuccess, onError) => {
  let colRef = firebase.firestore().collection(collection);
  colRef
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
};
/**
 * Get Document list from client collection
 * @param {*} formData : can be use for array ids
 * @param {*} onSuccess
 * @param {*} onError
 */
export const getClientsListbasedonIds = async (formData, onSuccess, onError) => {
  let colRef = firebase.firestore().collection(collection);
  await colRef
  .where(firebase.firestore.FieldPath.documentId(), 'in', formData)
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
};
/**
 * Get details of perticular client
 * @param {*} id : id of client want to get data of
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function getClient(id, onSuccess, onError) {
  let colRef = firebase.firestore().collection(collection);
  try {
    colRef
      .doc(id)
      .get()
      .then((doc) => {
        if (!doc.exists) {
          onError && onError({ status: false, error: 'Client not found' });
        }
        let data = doc.data();
        onSuccess &&
          onSuccess({ status: true, data: { client_id: doc.id, ...data } });
      })
      .catch((error) => {
        onError && onError({ status: false, error: error });
      });
  } catch (res_1) {
    onError && onError(res_1);
  }
}

/**
 * Create a new client
 * @param {*} formData Data to save in firestore
 * @param {*} acuityClientData Data to save in acuity data
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function addClient(
  formData,
  acuityClientData,
  onSuccess,
  onError
) {
  try {
    const resultNumber = await firebase
      .firestore()
      .collection(collection)
      .orderBy("clientNumber", "desc")
      .limit(1)
      .get();
    
    let newClientNumber = 101;

    resultNumber.forEach((doc) => {
      const apptData = doc.data();
      newClientNumber = apptData.clientNumber + 1;
    });

    let currentUserId = firebase.auth().currentUser.uid;
    const newClientData = {
      ...formData,
      createdBy: currentUserId,
      createdAt: new Date().getTime(),
      updatedAt: new Date().getTime(),
      clientNumber: newClientNumber,
    };

    const docRef = await firebase.firestore().collection(collection).add(newClientData);
    
    const responseItem = { ...newClientData, id: docRef.id };

    const clientres = await axiosInstance.post(
      `${BaseUrl}createClientAcuity`,
      { acuityClientData }
    );
    
    console.log('client acuity create', clientres);
    onSuccess && onSuccess({ status: true, data: responseItem });

  } catch (error) {
    console.log(`ERROR: ${error.message || error}`);
    onError && onError({ status: false, error: error.message || error });
  }
}

/**
 *  Update Data of perticular client
 * @param {*} id : id of client want to update
 * @param {*} formData  : only pass data should be updated
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function updateClient(
  id,
  formData,
  acuityClientData,
  onSuccess,
  onError
) {
  //console.log('datanew', formData);
  let currentUserId = firebase.auth().currentUser.uid;
  // console.log('datanewac', acuityClientData);
  let document = await firebase.firestore().collection(collection).doc(id);
  document
    .set(
      {
        ...formData,
        updatedAt: new Date().getTime(),
        updatedBy: currentUserId,
      },
      { merge: true }
    )
    .then(async () => {
      const responseItem = formData;

      //console.log('running');
      const clientupres = await axiosInstance.post(
        `${BaseUrl}updateClientAcuity`,
        { acuityClientData }
      );
      console.log('client acuity Updated', clientupres);
      onSuccess && onSuccess({ status: true, data: responseItem });
    })
    .catch((error) => {
      if (error.code === 5) {
        onError && onError({ status: false, message: 'Not Found' });
      }
      onError && onError({ status: false, error: error.code });
    });
}

/**
 * Remove client data from document
 * @param {*} id : id of client
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function deleteClient(
  id,
  acuityClientDatadele,
  onSuccess,
  onError
) {
  const document = firebase.firestore().collection(collection).doc(id);
  //console.log(acuityClientDatadele);
  document
    .delete()
    .then(async () => {
      //const responseItem = formData;

      //console.log('running');
      const clientupres = await axiosInstance.post(
        `${BaseUrl}deleteClientAcuity`,
        { acuityClientDatadele }
      );
      // console.log('client acuity Deleted', clientupres);
      onSuccess && onSuccess({ status: true, message: 'Delete successfull' });
    })
    .catch((error) => {
      onError && onError({ status: false, error: error });
    });
}

/**
 * Remove client data from document
 * @param {*} id : id of client
 * @param {*} onSuccess
 * @param {*} onError
 */
export async function deleteClientTaskGroup(id, taskItem) {
  try {
    const updates = {};
    const shiftName = taskItem?.shift.shiftId;
    // Dot Notation - will delete the specific song from songList
    updates[`shiftData.${shiftName}`] = firebase.firestore.FieldValue.delete();
    // Not necessary, but it's always a good practice
    //updates['updatedAt'] = firebase.firestore.FieldValue.serverTimestamp();

    firebase.firestore().collection(collection).doc(id).update(updates);
    return true;
  } catch (error) {
    return null;
  }
}

export async function deleteClientShift(id, taskItem) {
  try {
    const updates = {};
    const shiftName = taskItem?.shiftId;
    // Dot Notation - will delete the specific song from songList
    updates[`shiftData.${shiftName}`] = firebase.firestore.FieldValue.delete();
    // Not necessary, but it's always a good practice
    //updates['updatedAt'] = firebase.firestore.FieldValue.serverTimestamp();
    const ref = firebase.firestore().collection(collection).doc(id);
    await ref.update(updates);
    return true;
  } catch (error) {
    return null;
  }
}

/**
 * Upload logo of client :: not used for now need to change logo upload
 * @param {*} file
 * @param {*} onSuccess
 */
export async function uploadLogo(file, onSuccess) {
  // Create a root reference
  var storageRef = firebase.storage().ref();
  // Create the file metadata
  var metadata = {
    contentType: 'image/jpeg',
  };

  // Upload file and metadata to the object 'images/mountains.jpg'
  var uploadTask = storageRef.child('images/' + file.name).put(file, metadata);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      // console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log('Upload is running');
          break;
        default:
          break;
      }
    },
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case 'storage/unauthorized':
          // User doesn't have permission to access the object
          break;
        case 'storage/canceled':
          // User canceled the upload
          break;

        // ...

        case 'storage/unknown':
          // Unknown error occurred, inspect error.serverResponse
          break;
        default:
          break;
      }
    },
    () => {
      // Upload completed successfully, now we can get the download URL
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        onSuccess && onSuccess(downloadURL);
        // console.log('File available at', downloadURL);
      });
    }
  );
}

/**
 * Get Document list from client collection for summary
 * @param {*} formData : can be use for filter in future
 * @param {*} onSuccess
 * @param {*} onError
 */
export const getClientsSummary = (formData, onSuccess, onError) => {
  let colRef = firebase.firestore().collection(collection);
  colRef
    .limit(5)
    .get()
    .then((querySnapshot) => {
      let results = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        results.push({ id: doc.id, ...data });
      });
      onSuccess && onSuccess(results);
    })
    .catch((error) => {
      console.log('Error getting documents: ', error);
      onError && onError(error);
    });
};

// export const updateClientNumbers = async () => {
//   const colRef = firebase.firestore().collection(collection);

//   try {
//     const querySnapshot = await colRef.get();
//     let clientNumber = 101;

//     for (const docSnapshot of querySnapshot.docs) {
//       const docRef = colRef.doc(docSnapshot.id);
//       await docRef.update({
//         clientNumber: clientNumber,
//       });
//       clientNumber++;
//     }

//     console.log("Client numbers have been successfully updated!");
//   } catch (error) {
//     console.error("Error updating client numbers:", error);
//   }
// };