import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  InputLabel,
  MenuItem,
  FormControl,
  ListItemText,
  Select,
  Checkbox,
} from "@material-ui/core";

import { getRandomNumber } from "../../services/helpers";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // width: "100%",
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: "350px",
    },
    [theme.breakpoints.between(771, 940)]: {
      // maxWidth: 500,
      width: "450px",
    }
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  selectinput: {
    padding: "8px 10px"
  },
  selectlabel:{
    transform: 'translate(14px, 10px) scale(1)'
  }
}));

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
  className: "custom-select-menu",
};

export default function MultiSelectem(props) {
  const randomNumber = getRandomNumber();
  const classes = useStyles();
  const [personName, setPersonName] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    props?.value ? [...props?.value] : []
  );
  const sendData = (value) => {
    props?.onChange && props?.onChange(value);
  };
  const onSetSelectAll = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let newSelectAll = !selectAll;
    setSelectAll(newSelectAll);
    Checkall(newSelectAll, true);
  };
  const Checkall = (newSelectAll, setblank = false) => {
    if (newSelectAll === true) {
      let allvalues = props?.options?.map((oitem) => oitem?.value);
      setPersonName(allvalues);
      setSelectedValue(allvalues);
      sendData(allvalues);
    } else if (setblank === true) {
      setPersonName([]);
      setSelectedValue([]);
      sendData([]);
    }
  };
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    if (!value?.some((hcitem) => hcitem === "all")) {
      // console.log(value);
      let allvalues = value?.filter((hcitem) => {
        // console.log(hcitem);
        return hcitem !== "all"});

      // let allvalues = allval.map((itrv)=>{

      //   if(!itrv.clientid){
      //     return {clientid:itrv,shiftid:""}
      //   }else return itrv;
      // });
      
      if (allvalues?.length !== props?.options?.length) {
        setSelectAll(false);
      } else if (allvalues?.length === props?.options?.length) {
        setSelectAll(true);
      }
      setPersonName(allvalues);
      setSelectedValue(allvalues);
      sendData(allvalues);
    }
  };
  useEffect(() => {
    
    setSelectedValue(props?.value);
   
    setPersonName(props?.value);
  }, [props, props.value]);

  const renderOption = (opItem, index) => {
    return (
      <MenuItem key={opItem?.label} value={opItem?.value}>
        <Checkbox
          color="primary"
          size="small"
          checked={personName.indexOf(opItem?.value) > -1}
        />
        <ListItemText primary={opItem?.label} />
      </MenuItem>
    );
  };

  const renderSelectedValues = (selected) => {
    let selected_values = props.options?.filter((item) =>
      selected.includes(item?.value)
    );
    let selected_labels = selected_values?.map((item) => item?.label);
    return selected_labels.join(", ");
  };

  return (
    <>
      <FormControl variant="outlined" className={classes.formControl}>
        {typeof props?.label !== "undefined" && props?.label !== "" && (
          <InputLabel id={randomNumber + "demo-mutiple-checkbox-label"} className={classes.selectlabel}>
            {props?.label}
          </InputLabel>
        )}
        <Select
          labelId={randomNumber + "demo-mutiple-checkbox-label"}
          id={randomNumber + "demo-mutiple-checkbox"}
          onChange={handleChange}
          label={
            props?.label !== "undefined" && props?.label !== ""
              ? props?.label
              : ""
          }
          multiple
          value={selectedValue}
          renderValue={(selected) => renderSelectedValues(selected)}
          MenuProps={MenuProps}
          inputProps={{
            name: props?.name,
            className: `${classes.selectinput} ${props?.className}`,
          }}
          disabled={props.disabled || false}
        >
          {props.selectAllOption === true && (
            <MenuItem value="all" onClick={onSetSelectAll}>
              <Checkbox
                color="primary"
                size="small"
                checked={selectAll === true}
              />
              <ListItemText primary={"All"} />
            </MenuItem>
          )}
          
          {props.options?.length > 0 &&
            props.options?.map((opItem, index) => renderOption(opItem, index))}

        </Select>
      </FormControl>
    </>
  );
}
