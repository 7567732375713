const Columns = [
  {
    id: "orderId",
    numeric: false,
    disablePadding: true,
    label: "Order Id",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Employee Name",
    isSortable: true,
  },
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client Name",
    isSortable: true,
  },
  {
    id: "supplies",
    numeric: false,
    disablePadding: true,
    label: "Supplies",
    isSortable: true,
  },
  {
    id: "dateOfRequest",
    numeric: false,
    disablePadding: false,
    label: "Date Of Request",
    isSortable: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },
  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Approve/Reject",
    // rowclassName: "text-right",
    // className: "text-right pr-30",
  },
];

const ColumnsRes = [
  {
    id: "clientName",
    numeric: false,
    disablePadding: false,
    label: "Client",
    isSortable: true,
  },
  {
    id: "priority",
    numeric: false,
    disablePadding: false,
    label: "Priority",
    isSortable: true,
  },
  {
    id: "employeeName",
    numeric: false,
    disablePadding: true,
    label: "Employee Name",
    isSortable: true,
  },
  {
    id: "supplies",
    numeric: false,
    disablePadding: true,
    label: "Supplies",
    isSortable: true,
    isCollapsible: true,
  },
  {
    id: "dateOfRequest",
    numeric: false,
    disablePadding: false,
    label: "Date Of Request",
    isSortable: true,
    isCollapsible: true,
  }
];


const Rows = [
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000002",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559998907,
          ItemName: "Broom11",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559998907,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559998907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627669998907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627559996907,
    status: "Reject",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientOne",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientOne",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clientTwo",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientTwo",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
  {
    id: "SU000001",
    createdAt: "",
    content: {
      SupplieItems: [
        {
          LastDate: 1627559996907,
          ItemName: "Broom",
          Priority: "Standard",
          Status: "Reject",
          Note: "",
          Quantity: "3",
        },
        {
          ItemName: "Lint roller",
          Quantity: "1",
          Priority: "Standard",
          LastDate: 1627559996908,
          Note: "",
          Status: "",
        },
      ],
      employeeId: " 6WuohAzUg8R5hPwrqzYb",
      employeeName: "Emp001",
      clientName: "clienTwo",
      dateOfRequest: 1627559996907,
      status: "Pending",
      clientId: " Bpsk7z6XQOiCWeVz4Yqa",
      priority: "Normal",
    },
    owner: "",
    updatedAt: "",
    employeeId: " 6WuohAzUg8R5hPwrqzYb",
    employeeName: "Emp001",
    clientName: "clientTwo",
    dateOfRequest: 1627559996907,
    status: "Pending",
    clientId: " Bpsk7z6XQOiCWeVz4Yqa",
    priority: "Normal",
    supplies: "Broom(3),Lint roller(1)",
  },
];

export { Columns, ColumnsRes, Rows };
